import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GreetingHeader from '../../common/GreetingHeader';
import MonthlySpendLineChart from '../Charts/MonthlySpendLineChart';
import UpcomingBills from '../../common/UpcomingBills';
import CategorySpendingChart from '../Charts/CategorySpendingChart';
import Transactions from '../../common/Transactions';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchCategorySpendsAsync } from '../../../store/thunks/SpendingThunk';
import DrawerFullHeight from '../../common/DrawerFullHeight';
import BackIcon from '../../../assets/BackIcon';
import { FinleyCategorySpentData, SPENDSTATUS, STATUS } from '../../../store/slices/SpendingsSlice';
import { categoriesImage } from '../../common/categories';
import RadialImageChart from '../Charts/RadialImageChart';
import { formatCurrency } from '../../../utils/helper';
import { format } from 'date-fns/format';
import DrawerComponent from '../../common/DrawerComponent';
import KeypadBackIcon from '../../../assets/KeypadBackIcon';
import EditIconSmall from '../../../assets/EditIconSmall';
import { fetchSpendingCategoryTransactionAsync, postCategorySpendLimitAsync } from '../../../store/thunks/TransactionThunk';

export interface SpendingHomeProps {
  selectTab: (tabName: string) => void;
}

const SpendingHome: React.FC<SpendingHomeProps> = ({ selectTab }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [allTransactionFlag, setAllTransactionFlag] = useState(false);
  const [categorySpending, setCategorySpending] = useState(false);
  const [selectedCatogery, setSelectedCatogery] = useState<FinleyCategorySpentData>();
  const [editAmount, setEditAmount] = useState<number | null>(null);
  const [editCategory, setEditCategory] = useState<string | null>(null);

  const categoryData = categoriesImage.find(
    (item) => item.categoryName.toLowerCase() === selectedCatogery?.finleyCategory.toLowerCase()
  );

  const categorySpentResponseState = useSelector(
    (state: RootState) => state.spendingStore.fetchCategorySpentResponse
  );
  const selectedCategoryLimitData = useSelector(
    (state: RootState) => state.TransactionStore.spendCategoryTransactions
  );
  const postCategorySpendLimitStatus = useSelector(
    (state: RootState) => state.TransactionStore.postSpendCategoryLimitStatus
  );
  useEffect(() => {
    dispatch(fetchCategorySpendsAsync());
  }, [dispatch]);
  
  useEffect(() => {
    dispatch(fetchSpendingCategoryTransactionAsync(selectedCatogery?.finleyCategory));
  }, [dispatch, selectedCatogery]);


  const closeModal = () => {
    setShowEditModal(false);
  };

  const handleEditClick = (category: string, amount: number) => {
    setEditCategory(category);
    setEditAmount(amount);
    setShowEditModal(true);
  };
  // Use useCallback to memoize the function
  const handleTransactionFlagChange = useCallback((flag: boolean) => {
    setAllTransactionFlag(flag);
  }, []);
  const color =
    selectedCatogery?.spendStatus === SPENDSTATUS.OVER_SPENT
      ? 'red'
      : selectedCatogery?.spendStatus === SPENDSTATUS.OVER_THRESHOLD_SPENT
        ? '#FACC15'
        : '#5DE093';
  // Memoize components to avoid unnecessary re-renders
  const MemoizedMonthlySpendLineChart = React.memo(MonthlySpendLineChart);
  const MemoizedCategorySpendingChart = React.memo(CategorySpendingChart);
  const handleSave = async () => {
    if (!editCategory || editAmount === null) return;
  
    const formData = {
      limits: [
        {
          finley_category: editCategory,
          amount: editAmount,
        },
      ],
    };
  
    try {
      // Wait for the async action to complete
      const resultAction = await dispatch(postCategorySpendLimitAsync(formData));
      
      if (postCategorySpendLimitAsync.fulfilled.match(resultAction)) {
        // Fetch updated category spends immediately after successful action
        await dispatch(fetchCategorySpendsAsync());
  
        // Update the selected category locally
        if (selectedCatogery) {
          const updatedSpendRemaining = parseFloat(
            ((editAmount ?? (selectedCatogery.spendRemaining || 0)) - 
             (selectedCatogery.categorySpend || 0))
            .toFixed(2) // Ensure two decimal places
          );
  
          setSelectedCatogery({
            ...selectedCatogery,
            spendRemaining: updatedSpendRemaining > 0 ? updatedSpendRemaining : 0, // Prevent negative values
          });
        }
      } else {
        console.error("Failed to update spending limit", resultAction.payload);
      }
    } catch (error) {
      console.error("Error saving spending limit:", error);
    } finally {
      setShowEditModal(false);
    }
  };
  
  const handleNumberClick = (num: number | ".") => {
    setEditAmount((prev: any) => {
      if (prev === null) prev = 0;
      const prevString = prev.toString();
      if (num === ".") {
        if (prevString.includes(".")) return prev;
        return `${prevString}.`;
      }
      return parseFloat(prevString + num);
    });
  };

  const handleBackspace = () => {
    setEditAmount((prev) => {
      if (prev === null || prev.toString() === "0") return 0; // Set to 0 when cleared
      const prevString = prev.toString();
      return prevString.length > 1 ? parseFloat(prevString.slice(0, -1)) : 0; // Return 0 if the last digit is removed
    });
  };
  const editSpendingLimit = (
    <div className=''>
      <div className="w-full px-3 mt-4">
        <div className='text3 flex justify-center'>
          <span style={{ fontSize: "23px " }} className='mr-2'> {categoryData?.icon}</span> {categoryData?.categoryTitle}
        </div>
        <div className="flex justify-center">
          <div
            className="flex items-center justify-center"

          >
            <div className="flex flex-col text-center mt-4">
              <span className="text-green text3">Spending Limit</span>
              <span className="text-green mt-5 spendingEditText">
                ${editAmount !== null ? editAmount : selectedCatogery?.spendRemaining}
              </span>
              <span className="text-green intertextmediumparagraph mt-5">monthly</span>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-10 mt-24">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "<"].map((key: any, index) => (
          <button
            key={index}
            className="text6 flex justify-center items-center"
            onClick={() => {
              if (key === "<") {
                handleBackspace();
              } else {
                handleNumberClick(key);
              }
            }}
          >
            {key === "<" ? <KeypadBackIcon /> : key}
          </button>
        ))}
      </div>
      <button
        className="mt-28 bg-black text-white h-10 w-full rounded-full text-lg font-semibold"
        onClick={handleSave}
      >
        Save spending limit
      </button>
    </div>
  );


  const spendCategoryData = (
    <div className=" pt-14  mb-10  ">
      <div className="bg-white  ">
        <div className=" relative w-full">
          <div className='absolute top-0.5 left-2' onClick={() => setCategorySpending(false)}> <BackIcon /></div>
          <div className="carouselTitle w-full  flex justify-center">{categoryData?.categoryTitle}</div>
        </div>
        <hr className="mt-3"></hr>
        <div className="flex justify-center mt-6">
          <RadialImageChart
            percentage={selectedCatogery?.spendPercentage ? selectedCatogery.spendPercentage > 100 ? 100 : selectedCatogery?.spendPercentage : selectedCatogery?.spendPercentage} // Progress percentage
            size={128.58}
            strokeWidth={11}
            fontSize={50}
            emoji={categoryData?.icon}
            color={color}
          />
        </div>
        <div className='texth4 flex justify-center mt-2'>{formatCurrency(selectedCatogery?.categorySpend || 0)} spent</div>
        <div className='flex justify-center items-center mt-1'>
          <div className='chatDay mr-1 mt-0.5'>{formatCurrency(selectedCatogery?.spendRemaining || 0)} left this month </div>
          <div onClick={() => handleEditClick(selectedCatogery?.finleyCategory || '', selectedCatogery?.spendRemaining || 0)}><EditIconSmall /></div>
        </div>
        <div className='mt-6 px-4'>
          <div className='textlarge700'>
            Overview
          </div>
          <div className='flex text-neutral500 intertextxs justify-between mt-4'><div>Total spent this yearr</div><div>{selectedCategoryLimitData?.totalSpending}</div></div>
          <div className='flex text-neutral500 intertextxs justify-between mt-4'><div>Average per month </div><div>{selectedCategoryLimitData?.avgSpending}</div></div>

        </div>



        <div className='overflow-y-scroll  px-4 h-[55vh] pb-28 flex-grow hide-scrollbar mt-6'>

          {selectedCategoryLimitData?.data?.map((monthData: any) => (
            <div key={monthData.month} className="">
              {/* Month Header */}
              <div className="chatDay text-neutral500 ">
                {new Date(monthData.month).toLocaleString("default", { month: "long", year: "numeric" })}
              </div>
              <div>
                {/* Transactions */}
                {monthData.transactions.map((transaction: any, index: any) => (
                  <div
                    key={`${monthData.month}-${index}`}
                    className="w-full flex p-3 justify-between items-center self-stretch border border-borderGrey rounded-2xl my-1"
                  >
                    {/* Merchant Details */}
                    <div className="flex flex-col items-start">
                      <div className="text3">{transaction.name || "Unknown Merchant"}</div>
                      <div className="intertextxs text-neutral-500">
                        {transaction.finleyCategoryName || "N/A"}
                      </div>
                    </div>

                    {/* Transaction Details */}
                    <div className="flex flex-col items-end">
                      <div className={`text3 ${(transaction.amount < 0) ? "text-[#22C55E]" : ""}`}>
                        {(transaction.amount < 0)
                          ? formatCurrency(Math.abs(transaction.amount))
                          : formatCurrency(transaction.amount)}
                      </div>
                      <div className="intertextxs text-neutral-500">
                        {new Date(transaction.transactionDate).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

        </div>

      </div>
    </div>
  )

  return (
    <div className="flex flex-col h-screen">
      <GreetingHeader />

      <main className={`flex-1 overflow-y-auto hide-scrollbar py-3 px-3`}>
        <MonthlySpendLineChart />
        <div className="rounded-2xl border border-neutral-200 bg-white p-[25px]  text2">
          Spending by category
          <div className="flex flex-wrap justify-centre ">
            {categorySpentResponseState?.map((category) => (
              <div onClick={() => {
                setCategorySpending(true)
                setSelectedCatogery(category)
              }} className="pt-[25px]" style={{ width: '25%' }} key={category.finleyCategory}>
                <CategorySpendingChart
                  finleyCategory={category.finleyCategory}
                  spendPercentage={category.spendPercentage}
                  spendStatus={category.spendStatus}
                  spendRemaining={category.spendRemaining || 0}
                />
              </div>
            ))}
          </div>

          <div />

        </div>
        <UpcomingBills />
        {/* Only trigger a re-render on the Transactions component */}
        <div className="mb-28">
          <Transactions
            allTransactionFlag={allTransactionFlag}
            setAllTransactionFlag={handleTransactionFlagChange} />
        </div>
      </main>

      <div>

        <DrawerFullHeight isOpen={categorySpending} onClose={function (): void {
          setCategorySpending(false);
        }} title={undefined} content={spendCategoryData} />
      </div>
      <div>
        <DrawerComponent
          isOpen={
            showEditModal
          }
          onClose={closeModal}
          title=''
          // title={'transposrt'}
          content={editSpendingLimit}
        />
      </div>
    </div>
  );
};

export default SpendingHome;
