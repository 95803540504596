
  export const categoriesImage = [
   { categoryName: "housing_and_utilities", icon: "🏠",categoryTitle:'Housing' }, 
   { categoryName: "education_and_childcare", icon: " 🎓",categoryTitle:'Education' },
   { categoryName: "transportation", icon: "🚗" ,categoryTitle:'Transport'},
   { categoryName: "healthcare_and_medical", icon: "🏥",categoryTitle:'Healthcare' },
   { categoryName: "insurance", icon: "🛡️" ,categoryTitle:'Insurance'},
   { categoryName: "loans_and_credit_cards", icon: "💳" ,categoryTitle:'Loans'},
   { categoryName: "groceries", icon: "🛒" ,categoryTitle:'Groceries'},
   { categoryName: "miscellaneous", icon: "⚙️" ,categoryTitle:'Misc'},
   { categoryName: "entertainment", icon: "🎈",categoryTitle:'Entertain' },
   { categoryName: "dining_out", icon: "🍴",categoryTitle:'Dining Out' },
   { categoryName: "shopping", icon: "🛍️",categoryTitle:'Shopping' },
   { categoryName: "travel", icon: "✈️",categoryTitle:'Travel' },
 ];