
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from '../pages/Home/Login';
import Home from '../pages/Home/Home';
import NotFound from '../../components/common/NotFound';
import OnBoarding from '../pages/OnBoardingStep/OnBoarding';
import LandingState from '../pages/LandingState/LandingState';
import Survey from '../pages/Survey/Survey';
import SurveyPage from '../pages/Survey/SurveyQuePage';
import Chat from '../pages/chat/Chat';
import SurveyQuePage from '../pages/Survey/SurveyQuePage';
import CountdownToClose from './CountdownToClose';
import AccountDetails from '../Accounts/AccountDetails';
import { OverView } from '../pages/Spendings/OverView';
import ConfirmIncome from '../Accounts/ConfirmIncome';
import SendingLimitsOverviewComponent from '../pages/SpendingLimits/SendingLimitsOverviewComponent';
import SpendingLimits from '../pages/SpendingLimits/SpendingLimits';
import AppUrlListener from './AppUrlListener';
import SchedulingMeeting from '../pages/CoachingHome/SchedulingMeeting';
import ForgotPassword from '../pages/Home/ForgotPassword';
import CreatePassword from '../pages/Home/CreateNewPassword';
export function Main() {
    const isAuthenticated = !!localStorage.getItem("accessToken"); // Check if token exists
    const location = useLocation();


    return (
    <>
       <AppUrlListener />
   

        <Routes>
            {isAuthenticated ? (
                <>
                    <Route path="/home" element={<Home />} />
                    
                    <Route path="/onboarding" element={<OnBoarding />} />
                    <Route path="/connect-account" element={<AccountDetails />} />
                    <Route path="/new-connect-account" element={<AccountDetails />} />
                    <Route path="/confirm-income" element={<ConfirmIncome />} />
                    <Route path="/spend-limit" element={<SpendingLimits />} />
                    <Route path="/spend-limit-overview" element={<SendingLimitsOverviewComponent />} />

                    <Route path="/login" element={<Login />} />
                    <Route path="/survey" element={<Survey />} />
                    <Route path="/survey-que" element={<SurveyPage/>} />
                    <Route path="/overview" element={<OverView />} />
                    <Route path="/chat" element={<Chat />} />
                    <Route path="/survey-que" element={<SurveyQuePage/>} />
                 
                    <Route path="/callback" element={<AccountDetails />} />
                    <Route path="/" element={<LandingState />} />
                    <Route path="/ScheduleMeeting/:coachId" element={<SchedulingMeeting />} />
             
                    {/* Handle 404 for authenticated users */}
                    <Route path="*" element={<NotFound />} />
                </>
            ) : (
                <>
                  
                    <Route path="/" element={<LandingState />} />
                    <Route path="/reset-password" element={<CreatePassword />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/callback" element={<CountdownToClose />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/onboarding" element={<OnBoarding />} />
                    <Route path="/redirect" element={<CountdownToClose />} />
                    {/* If user is not authenticated, redirect to login */}
                    {location.pathname !== '/' && (
                        <Route path="*" element={<Navigate to="/" />} />
                    )}
                </>
            )}
        </Routes>
        </>
    );
}

export default Main;
