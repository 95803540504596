import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { fetchAccountDetailAsync, fetchSalaryAsync, postSalaryAsync } from "../../store/thunks/TransactionThunk";
import useDeepLinking from "../../hooks/useDeepLinking";
import Header from "../common/Header";
import { MessageSequence } from "../pages/Spendings/MessageSequence";
import "../../App.css";
import HeaderWithBackIcon from "../common/HeaderWithBackIcon";
import { useNavigate } from "react-router";

export default function ConfirmIncome() {
  const dispatch = useDispatch<AppDispatch>();
  const salary: any = useSelector((state: RootState) => state.TransactionStore.salaryDetails);
  const [userSalary, setUserSalary] = useState<string>('');
  const [showMessages, setShowMessages] = useState(false); // To toggle between the UI and the message component
  useDeepLinking();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccounts = async () => {
      await dispatch(fetchAccountDetailAsync());
      await dispatch(fetchSalaryAsync());
    };

    fetchAccounts();
  }, [dispatch]);

  useEffect(() => {
    if (salary && salary?.user_provided) {
      setUserSalary(salary?.user_provided);
    }
    else if (salary && salary.finley_identified) {
      setUserSalary(salary?.finley_identified);
    }
  }, [salary]);


  const handleClick = async () => {

    const data = { salary: parseFloat(userSalary) || 0 };
    await dispatch(postSalaryAsync(data));
    setShowMessages(true); // Show the message component

  }

  if (showMessages) {
    return <MessageSequence />;
  }
  const handleBackClick = () => {
    navigate(-1)
  };
  return (
    <div className="lg:container lg:max-w-md h-screen flex flex-col px-4 bg-customBgColor">
      <HeaderWithBackIcon onBackClick={handleBackClick} />

      <div className="font-serif text-xl font-bold leading-7 tracking-tightCustom">
        {salary?.user_provided !== null
          ? "Next, can you confirm your monthly income?"
          : "Next, how much do you make per month?"}
      </div>
      <div className="bg-background w-full justify-center pb-10 pt-3 lg:px-0">
        <div className="relative w-full">
          <textarea
            value={`$  ${userSalary || ""}`}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^0-9.]/g, "");
              setUserSalary(inputValue);
            }}
            onInput={(e) => {
              const textarea = e.target as HTMLTextAreaElement;
              textarea.style.height = "48px";
              textarea.style.height = `${textarea.scrollHeight}px`;
            }}
            placeholder="Enter your monthly income"
            className="px-4 pr-10 py-3 w-full resize-none rounded-md overflow-hidden text3"
            style={{ height: "48px", borderColor: "#E5E5E5" }}
          />
        </div>
      </div>
      <button
        onClick={handleClick}
        disabled={!userSalary}
        className={`flex justify-center items-center cursor-pointer w-full px-8 py-2 rounded-full 
    ${!userSalary ? "bg-gray-400" : "bg-loginBg text-white"}`}
      >
        Continue
      </button>
    </div>
  );
}
