import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { ChatAsync, FetchChatMessagesAsync, FetchConversationsAsync } from "../../../store/thunks/ChatThunk";
import SendIcon from "../../../assets/SendIcon";
import AppLogo from "../../../assets/AppLogo";
import BackIcon from "../../../assets/BackIcon";
import HamBurgerMenu from "../../../assets/HamBurgerMenu";
import './style.css';
import { isToday, isYesterday, isThisWeek, parseISO } from 'date-fns';
import { resetChatMessage, Transaction } from "../../../store/slices/ChatSlice";
import RightDrawer from "../../common/RightDrawer";
import DOMPurify from 'dompurify';
interface TempItem {
    type: "user" | "system";
    msg: string;
}

interface ChatProps {
    isLoadedFromHomeScreen?: boolean; // Optional prop
    selectTab?: (tab: string) => void;
}
const Chat = ({ isLoadedFromHomeScreen = false, selectTab }: ChatProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const [tempArray, setTempArray] = useState<TempItem[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [conversationID, setConversationID] = useState<string>('');
    const [menuOpen, setMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
    const headerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedConversationId, setSelectedConversationId] = useState<string | null>('');
    const conversations = useSelector((state: RootState) => state.ChatStore.chatConversations);
    const chatHomePageMessage = useSelector((state: RootState) => state.ChatStore.chatHomePageMessage);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [hasLoadedFromHomeScreen, setHasLoadedFromHomeScreen] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
     const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };
   useEffect(() => {
        if (selectedConversationId) {
            setTempArray([]);
            dispatch(FetchChatMessagesAsync(selectedConversationId)).then(response => {
                if (response.payload?.transactions) {
                    const messages = response.payload.transactions
                        .map((transaction: any) => ({
                            type: transaction.sender_type === "user" ? "user" : "system",
                            msg: transaction.message_text
                        }))
                        .reverse();

                    setTempArray(messages);
                }
            });
        }
    }, [selectedConversationId, dispatch]);

    const mainContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleResize = () => {
            if (mainContainerRef.current) {
                mainContainerRef.current.style.height = `${window.innerHeight}px`;
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // useEffect(() => {
    //     if (isLoadedFromHomeScreen) {
    //         setInputValue(chatHomePageMessage || '');
    //         const chatMsgFromHome = chatHomePageMessage
    //         dispatch(resetChatMessage());
    //         send(chatMsgFromHome || '')
    //         isLoadedFromHomeScreen = false;
    //     }
    // }, [isLoadedFromHomeScreen]);

    useEffect(() => {
        dispatch(FetchConversationsAsync());
    }, [dispatch, conversationID]);

    useEffect(() => {
        const headerHeight = headerRef.current?.offsetHeight || 0;
        const latestUserMessageIndex = tempArray.map(item => item.type).lastIndexOf("user");
        const latestUserMessageRef = questionRefs.current[latestUserMessageIndex];

        if (latestUserMessageRef && containerRef.current) {
            const questionPosition = latestUserMessageRef.offsetTop;
            containerRef.current.scrollTo({
                top: questionPosition - headerHeight - 20,
                behavior: 'smooth'
            });
        }
    }, [tempArray]);

    const send = useCallback(async (query: string) => {
        if (inputValue.trim() === '' && !isLoadedFromHomeScreen) return;
        if (query === '' && isLoadedFromHomeScreen) {
            return;
        }
        setTempArray(prevArray => [...prevArray, { type: 'user', msg: query }, { type: 'system', msg: "" }]);
        setInputValue('');
        if (textareaRef.current) {
            textareaRef.current.style.height = '48px';  // Default height
        }
        setIsLoading(true);
        const formData = { message: query, conversation_id: conversationID };

        try {
            const response = await dispatch(ChatAsync(formData));
            if (response.payload) {
                if (conversationID === '') {
                    setConversationID(response.payload.conversation_id);
                }

                setTimeout(() => {
                    setTempArray(prevArray => [
                        ...prevArray.slice(0, -1),
                        { type: 'system', msg: response.payload.message_text }
                    ]);
                    setIsLoading(false);
                }, 1000);
            }
          
        } catch (error) {
            setIsLoading(false);
            console.error('Error:', error);
        }
    }, [inputValue, isLoadedFromHomeScreen, conversationID, dispatch]);

    useEffect(() => {
        if (isLoadedFromHomeScreen && !hasLoadedFromHomeScreen) {
            setInputValue(chatHomePageMessage || '');
            const chatMsgFromHome = chatHomePageMessage;
            dispatch(resetChatMessage());
            send(chatMsgFromHome || '');
            setHasLoadedFromHomeScreen(true);
        }
    }, [isLoadedFromHomeScreen, hasLoadedFromHomeScreen, chatHomePageMessage, dispatch, send]);
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey && !isLoading) {
            event.preventDefault();
            send(inputValue);
        }
    };

    const groupConversationsByDate = (transactions: Transaction[] | undefined) => {
        const grouped = {
            today: [] as Transaction[],
            yesterday: [] as Transaction[],
            thisWeek: [] as Transaction[],
            lastWeek: [] as Transaction[],
        };

        transactions?.forEach((transaction: Transaction) => {
            const date = parseISO(transaction.created_at);
            if (isToday(date)) {
                grouped.today.push(transaction);
            } else if (isYesterday(date)) {
                grouped.yesterday.push(transaction);
            } else if (isThisWeek(date)) {
                grouped.thisWeek.push(transaction);
            } else {
                grouped.lastWeek.push(transaction);
            }
        });

        return grouped;
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClick = (id: string) => {
        setSelectedConversationId(id);
        setConversationID(id)
        toggleDrawer();
        toggleMenu()
    };

    return (
        <div className="flex flex-col h-screen pb-12 pt-3">
            <div ref={mainContainerRef} className="h-screen overflow-hidden">


                <div ref={headerRef} className="header flex justify-between pb-16 items-center px-3 bg-customBgColor fixed left-0 w-full z-10">
                    <div className="left-0" onClick={() => selectTab?.('Home')}>
                        <BackIcon />
                    </div>
                    <div className="flex justify-center items-center mx-auto">
                        <AppLogo />
                        <div className="ml-2 carouselTitle">
                            <h4>Finley</h4>
                        </div>
                    </div>
                    <div onClick={toggleDrawer}>
                        <HamBurgerMenu />
                    </div>
                </div>



                <RightDrawer isOpen={isDrawerOpen} onClose={toggleDrawer}>
                    {Object.entries(groupConversationsByDate(conversations?.transactions)).map(([key, group], index) => {
                        if (group.length === 0) return null;
                        let header = '';
                        if (key === 'today') header = 'Today';
                        if (key === 'yesterday') header = 'Yesterday';
                        if (key === 'thisWeek') header = 'This Week';
                        if (key === 'lastWeek') header = 'Last Week';

                        return (
                            <div key={key} className="conversation-group mb-5">
                                {/* Header Button */}
                                <div>
                                    <button className="h-10 pl-5 text-neutral-600 rounded-full intertextsmall cursor-pointer">
                                        {header}
                                    </button>
                                </div>
                                {/* Transaction Buttons */}
                                <div className="flex flex-col items-start gap-[10px] w-full">
                                    {group.map((transaction) => (
                                        <div key={transaction.id} className="flex flex-col items-start gap-[10px] w-full">
                                            <button
                                                className={`w-full h-10 text-left text-darkBlue pl-5 rounded-full text3 cursor-pointer ${selectedConversationId === transaction.id ? 'bg-blackButton text-white rounded-full' : ''}`}
                                                onClick={() => handleClick(transaction.id)}
                                            >
                                                {transaction.title}
                                            </button>


                                        </div>
                                    ))}
                                </div>
                                {index < Object.entries(groupConversationsByDate(conversations?.transactions))?.length - 1 && <div className="pt-5">
                                    <hr className="border-t border-neutral-200" style={{ height: 'var(--spacing-0, 1px)' }} />
                                </div>}
                            </div>
                        );
                    })}
                </RightDrawer>


                <div className="lg:flex lg:justify-center pt-20">
                    <div className="sm:relative flex flex-col justify-between lg:justify-end h-screen lg:w-[800px]">
                        <div ref={containerRef} className="overflow-hidden pb-[1000px] overflow-y-auto scrollbar-hide scroll-container lg">
                            <div className="pb-14 lg:px-5">

                                {tempArray.map((item, index) => (
                                    <div className={`px-3 flex py-2 my-10 rounded-2xl w-fit max-w-[75%] ${item.type === 'user' ? 'bg-gray-300 px-5 ml-auto mr-3' : ''}`} key={index} ref={(el) => (questionRefs.current[index] = el)}>
                                        {item.type === 'system' && <div className=" custom-div mt-1.5"><p><AppLogo /></p></div>}
                                        {item.msg === "" ? (
                                            <div className="ml-3 loader">
                                                <div className="loader-dot"></div>
                                                <div className="loader-dot"></div>
                                                <div className="loader-dot"></div>
                                            </div>
                                        ) : (
                                            <div className={`custom-div text3 text-wrap mt-[2px] ${item.type === 'user' ? '' : 'ml-4'}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.msg) }} ></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fixed bg-background w-full flex bottom-0 left-0 right-0 justify-center pb-10 pt-3 px-3 lg:px-0">
                    <div className="relative w-full">
                        <textarea
                            value={inputValue}
                            ref={textareaRef} 
                            onKeyDown={handleKeyDown}
                            onChange={(event) => setInputValue(event.target.value)}
                            onInput={(e) => {
                                const textarea = e.target as HTMLTextAreaElement;
                 
                                // Reset height to the initial value when textarea is empty
                                if (textarea.value === '') {
                                    textarea.style.height = '48px'; // Default height
                                } else {
                                    // Dynamically adjust height based on content
                                    textarea.style.height = '48px'; // Reset to initial height before adjusting
                                    textarea.style.height = `${textarea.scrollHeight}px`;
                                }
                            }}
                            placeholder="Ask Finley about your money..."
                            className="px-4 pr-10 py-3 w-full resize-none rounded-md overflow-hidden text3 h-"
                            style={{ height: '48px', borderColor: '#E5E5E5' }}
                        />
                        <div onClick={() => !isLoading && send(inputValue)} className="absolute bottom-2 right-3 transform -translate-y-1/2 cursor-pointer">
                            <SendIcon disabled={isLoading} />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Chat;
