import React from "react";
import { Dialog, DialogPanel, DialogTitle, Transition } from "@headlessui/react";

import BackIcon from "../../assets/BackIcon";



interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  content: React.ReactNode;
  onBack?: () => void; 
}

const DrawerFullHeight: React.FC<DrawerProps> = ({ isOpen, onClose, title, content, onBack }) => {
  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10 h-full bg-white" onClose={onClose}>
        <div className="fixed inset-0 bg-neutral-100 bg-opacity-50" />
        <div className="fixed inset-0 flex justify-center">
          <div className="fixed inset-x-0 bottom-0 flex max-w-full h-[100%] modal-content ">
            <Transition.Child
              as={React.Fragment}
              // enter="transition-transform ease-in-out"
              // enterFrom="translate-y-full"
              // enterTo="translate-y-0"
              // leave="transition-transform ease-in-out"
              // leaveFrom="translate-y-0"
              // leaveTo="translate-y-full"
            >
              <DialogPanel className="pointer-events-auto w-full max-w-md transform shadow-xl  bg-white">
                <div className="flex flex-col ">
                  <div className="w-full flex justify-between items-center  pb-6 px-4">
                    {/* Back Button (optional, if onBack is provided) */}
                    {onBack && (
                      <button
                        onClick={onBack}
                        className="text-neutral-700 text-2xl"
                        aria-label="Back"
                      >
                        <BackIcon />
                      </button>
                    )}
                  </div>
                  <div className="relative flex-1 overflow-y-auto ">
                    <div className=" text-large400 font-serif ">{content}</div>
                  </div>
                </div>
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DrawerFullHeight;
