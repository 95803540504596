import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GreetingHeader from '../../common/GreetingHeader';
import MonthlySpendLineChart from '../Charts/MonthlySpendLineChart';
import UpcomingBills from '../../common/UpcomingBills';
import CategorySpendingChart from '../Charts/CategorySpendingChart';
import Transactions from '../../common/Transactions';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchCategorySpendsAsync } from '../../../store/thunks/SpendingThunk';
import DrawerFullHeight from '../../common/DrawerFullHeight';
import BackIcon from '../../../assets/BackIcon';
import { FinleyCategorySpentData, SPENDSTATUS } from '../../../store/slices/SpendingsSlice';
import { categoriesImage } from '../../common/categories';
import RadialImageChart from '../Charts/RadialImageChart';
import { formatCurrency } from '../../../utils/helper';
import { format } from 'date-fns/format';

export interface SpendingHomeProps {
  selectTab: (tabName: string) => void;
}

const SpendingHome: React.FC<SpendingHomeProps> = ({ selectTab }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [allTransactionFlag, setAllTransactionFlag] = useState(false);
  const [categorySpending, setCategorySpending] = useState(false);
  const [selectedCatogery, setSelectedCatogery] = useState<FinleyCategorySpentData>();

  const categoryData = categoriesImage.find(
    (item) => item.categoryName.toLowerCase() === selectedCatogery?.finleyCategory.toLowerCase()
  );


  const categorySpentResponseState = useSelector(
    (state: RootState) => state.spendingStore.fetchCategorySpentResponse
  );

  useEffect(() => {
    dispatch(fetchCategorySpendsAsync());
  }, [dispatch]);

  // Use useCallback to memoize the function
  const handleTransactionFlagChange = useCallback((flag: boolean) => {
    setAllTransactionFlag(flag);
  }, []);
  const color =
    selectedCatogery?.spendStatus === SPENDSTATUS.OVER_SPENT
      ? 'red'
      : selectedCatogery?.spendStatus === SPENDSTATUS.OVER_THRESHOLD_SPENT
        ? '#FACC15'
        : '#5DE093';
  // Memoize components to avoid unnecessary re-renders
  const MemoizedMonthlySpendLineChart = React.memo(MonthlySpendLineChart);
  const MemoizedCategorySpendingChart = React.memo(CategorySpendingChart);
  const spendCategoryData = (
    <div className="p-4">
      <div className="bg-white mt-3 ">
        <div className="flex pt-3 ">
          <div onClick={() => setCategorySpending(false)}> <BackIcon /></div>
          <div className="carouselTitle mx-auto">{categoryData?.categoryTitle}</div>
        </div>
        <hr className="mt-3"></hr>
        <div className="flex justify-center mt-6">
          <RadialImageChart
            percentage={selectedCatogery?.spendPercentage ? selectedCatogery.spendPercentage > 100 ? 100 : selectedCatogery?.spendPercentage : selectedCatogery?.spendPercentage} // Progress percentage
            size={128.58}
            strokeWidth={11}
            fontSize={50}
            emoji={categoryData?.icon}
            color={color}
          />
        </div>
        <div className='texth4 flex justify-center mt-2'>{formatCurrency(selectedCatogery?.categorySpend || 0)} spent</div>
        <div className='chatDay flex justify-center mt-2'>{formatCurrency(selectedCatogery?.spendRemaining || 0)} $200 left this month</div>
        <div className='mt-6'>
          <div className='textlarge700'>
            Overview
          </div>
          <div className='flex text-neutral500 intertextxs justify-between'><div>Total spent this year</div><div>$0,000.00</div></div>
          <div className='flex text-neutral500 intertextxs justify-between'><div>Average per month </div><div>$0,000.00</div></div>

        </div>

        <div className='mt-6'>
          <div className='chatDay text-neutral500'>December</div>

          <div
                        className="w-full flex p-3 justify-between items-center self-stretch border border-borderGrey rounded-2xl mb-3 mt-4"
                    >
                        {/* Merchant Details */}
                        <div className="flex flex-col items-start">
                            <div className="text3">{'Uber'|| "Unknown Merchant"}</div>
                            <div className="intertextxs text-neutral-500">{'Transport' || "N/A"}</div>
                        </div>

                        {/* Transaction Details */}
                        <div className="flex flex-col items-end">
                            <div
                                className={`text3 ${1000 < 0 ? "text-[#22C55E]" : ""
                                    }`}
                            >
                                {1000 < 0 ? formatCurrency(Math.abs(1000)) : formatCurrency(1000)}
                            </div>
                            <div className="intertextxs text-neutral-500">{format('10/20/1000','MM-dd-yyyy')}</div>
                        </div>
                    </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="flex flex-col h-screen">
      <GreetingHeader />

      <main className={`flex-1 overflow-y-auto hide-scrollbar py-3 px-3`}>
        <MonthlySpendLineChart />
        <div className="rounded-2xl border border-neutral-200 bg-white p-[25px]  text2">
          Spending by category
          <div className="flex flex-wrap justify-centre ">
            {categorySpentResponseState?.map((category) => (
              <div onClick={() => {
                setCategorySpending(true)
                setSelectedCatogery(category)
              }} className="pt-[25px]" style={{ width: '25%' }} key={category.finleyCategory}>
                <CategorySpendingChart
                  finleyCategory={category.finleyCategory}
                  spendPercentage={category.spendPercentage}
                  spendStatus={category.spendStatus}
                  spendRemaining={category.spendRemaining || 0}
                />
              </div>
            ))}
          </div>
         
          <div />
     
        </div>
        <UpcomingBills />
        {/* Only trigger a re-render on the Transactions component */}
        <div className="mb-28">
          <Transactions
            allTransactionFlag={allTransactionFlag}
            setAllTransactionFlag={handleTransactionFlagChange} />
        </div>
      </main>

      <div>

        <DrawerFullHeight isOpen={categorySpending} onClose={function (): void {
          setCategorySpending(false);
        }} title={undefined} content={spendCategoryData} />
      </div>
    </div>
  );
};

export default SpendingHome;
