
import { useNavigate } from "react-router";


type SurveySubmitProps = {
    onSubmit: () => void;
};
const SurveySubmit = ({ onSubmit }: SurveySubmitProps) => {
    const navigate = useNavigate();

    const handleSubmit = () => {
        onSubmit();
        navigate('/home');
    };

    return (
        <div className="px-3">
            <div className="mt-[10px] carouselTitle">
                Great! Thanks for filling me in.
            </div>
            <div className="text3 mt-2 text-darkBlue">
                I’ll check in periodically to see if things are feeling different.
            </div>
            <div className="text3 text-darkBlue mt-5">
                Here's what I recommend you do next:
            </div>
            <div className="text3 font-semibold text-darkBlue ">
                💬 Talk to Your Money
            </div>
            <div className="text3 text-darkBlue ">
                Ask me anything about your finances - from daily spending to big picture goals
            </div>
            <div className="text3 font-semibold text-darkBlue mt-5">
                🔗 Connect More Accounts
            </div>
            <div className="text3 text-darkBlue ">
                Add all your financial accounts to get a complete view of your money
            </div>
            <div className="text3 font-semibold text-darkBlue mt-5">
                📝 Create a Spending Plan
            </div>
            <div className="text3 text-darkBlue ">
                Let me help you automatically generate a plan to reduce spending and boost cashflow
            </div>
            <div className="w-full mt-7 ">
                <button className="bg-blackButton carouselSubTitle text-white w-full py-2 px-6 rounded-full mb-4" onClick={handleSubmit}>
                    Let's Get Started
                </button>
            </div>
        </div>
    )
}
export default SurveySubmit;



// I’ll check in periodically to see if things are feeling different. 

// Here's what I recommend you do next:
// 💬 Talk to Your Money
// Ask me anything about your finances - from daily spending to big picture goals

// 🔗 Connect More Accounts
// Add all your financial accounts to get a complete view of your money

// 📝 Create a Spending Plan
// Let me help you automatically generate a plan to reduce spending and boost cashflow