import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchDailySpendsAsync } from '../../../store/thunks/SpendingThunk';
import { useSelector } from 'react-redux';
import LineChart from './LineChart';
import { SpendingHeaderMessage } from '../Spendings/SpendHeaderMessage';

const MonthlySpendLineChart = () => {
    const dispatch = useDispatch<AppDispatch>();
    const spendingState = useSelector((state:RootState)=>state.spendingStore.fetchDailySpendsResponse)
    
    const avgDailySpendingLimit = useSelector((state:RootState)=>state.spendingStore.fetchDailySpendsResponse?.avgDailySpendingLimit);
    const dailySpending = useSelector((state:RootState)=>state.spendingStore.fetchDailySpendsResponse?.dailySpending);
    useEffect(() => {
        const fetchDailySpends = async () => {
            await dispatch(fetchDailySpendsAsync()); 
        }
        fetchDailySpends();
    }, [dispatch]);
    interface DailySpending {
        day: number;
        spendingTillDate?: number; //optional
        spendingLimitTillDate: number;
      }

    const getDaysInMonth = (year:number, month:number) => {
        return new Date(year, month + 1, 0).getDate();
      };
      
      // Current month and year
      const today = new Date();
      const daysInMonth = getDaysInMonth(today.getFullYear(), today.getMonth());
      
      // Extract avgDailySpendingLimit and dailySpending
     // const { avgDailySpendingLimit, dailySpending } = spendingState;
      
     const updatedDailySpending: DailySpending[] = Array.from({ length: daysInMonth }, (_, index) => {
        const day = index + 1; // Day starts from 1
        const existingData = dailySpending?.find((item) => item.day === day);
    
        // Calculate spendingLimitTillDate only if avgDailySpendingLimit is available
        const spendingLimitTillDate = avgDailySpendingLimit
          ? parseFloat((avgDailySpendingLimit * day).toFixed(2))
          : 0;
    
        // Return object without spendingTillDate if it doesn't exist in the existing data
        const dailySpendingData: DailySpending = {
          day,
          spendingLimitTillDate,
        };
    
        if (existingData?.spendingTillDate !== undefined && existingData?.spendingTillDate !== null) {
          dailySpendingData.spendingTillDate = existingData.spendingTillDate;
        }
    
        return dailySpendingData;
      });
 
    return (
        <div className='flex flex-col rounded-2xl border border-neutral-200 bg-white p-[25px] mb-3'>
            <SpendingHeaderMessage monthlyLimit={spendingState?.monthlyLimit||0} remainingLimit={spendingState?.remainingLimit||0}></SpendingHeaderMessage>
            <LineChart monthlyData={updatedDailySpending} />
        </div>
    );
};

export default MonthlySpendLineChart;
