import React from 'react';
import { SPENDSTATUS } from '../../../store/slices/SpendingsSlice';
import { categoriesImage } from '../../common/categories';
import { formatCurrency } from '../../../utils/helper';
import RadialImageChart from './RadialImageChart';


export interface CategorySpendingChartProps {
  finleyCategory: string;
  spendPercentage: number;
  spendStatus: string;
  spendRemaining: number;
}

const CategorySpendingChart: React.FC<CategorySpendingChartProps> = ({
  finleyCategory,
  spendPercentage,
  spendStatus,
  spendRemaining
}) => {

  // Determine the color based on spendStatus
  const color =
    spendStatus === SPENDSTATUS.OVER_SPENT
      ? 'red'
      : spendStatus === SPENDSTATUS.OVER_THRESHOLD_SPENT
        ? '#FACC15'
        : '#5DE093';

  // Find the category data
  const categoryData = categoriesImage.find(
    (item) => item.categoryName.toLowerCase() === finleyCategory.toLowerCase()
  );


  return (
    <div className="flex flex-col items-center gap-1 ">
      <div className="intertextxs text-neutral-500">
        {categoryData?.categoryTitle || finleyCategory}
      </div>
      <div className="w-['74px'] h-['74px']">

        <RadialImageChart
          percentage={spendPercentage > 100 ? 100 : spendPercentage} // Progress percentage
          size={73.75}
          strokeWidth={6}
          emoji={categoryData?.icon}
          color={color}
        />
      </div>
      <div className="intertextxs text-neutral-500 leading-[12px]">
        {formatCurrency(Math.abs(spendRemaining))}

      </div>
      <div
        className="intertextxxs text-neutral-500 border-solid border-[#22C55E] p-0 h-[8px] text-[10px] leading-[8px] overflow-hidden text-center "
      >
        {spendStatus === SPENDSTATUS.OVER_SPENT ? 'over limit' : 'left'}
      </div>
    </div>
  );
};

export default CategorySpendingChart;
