import { useEffect, useState } from 'react';
import key from '../../../assets/key.svg';
import user from '../../../assets/user.svg';
import EditIcon from '../../../assets/EditIcon';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import CreatePasswordComponent from '../Home/CreatePasswordComponent';
import { resetCreatePasswordStatus, resetForgotPasswordStatus, resetLoginStatus, } from '../../../store/slices/AuthSlice';
import { useNavigate } from 'react-router';

interface SettingsProps {
    activeSection: string;
    
  }

export const Settings:React.FC<SettingsProps>  = ({ activeSection }) => {
    const userData = useSelector((state: RootState) => state.MasterStore.userAccount);
    const [isForgotPasswordVisible, setForgotPasswordVisible] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    const clearLoginStatus = () => {
        dispatch(resetForgotPasswordStatus());
        dispatch(resetCreatePasswordStatus());
        dispatch(resetLoginStatus());
    }
    useEffect(() => {
       // clearLoginStatus();
    }, [])
    // Toggle the visibility of ForgotPassword component
    const toggleForgotPassword = () => {
       clearLoginStatus();
        setForgotPasswordVisible(prev => !prev);
    };

    const handlePasswordChangeSuccess = () => {
        setForgotPasswordVisible(false);

      };
    return (
        <div className="flex flex-col items-start gap-4 pt-6">
            {/* Conditionally render email and password sections */}
            {!isForgotPasswordVisible && (
                <>
                    <div className="flex justify-center items-center gap-2 self-stretch border border-neutral-200 bg-white rounded-2xl">
                        <div className="flex p-[25px] items-center gap-4 flex-1">
                            <div> <img src={user} alt="account" sizes="6" /></div>
                            <div className="flex flex-col justify-center items-start gap-[var(--spacing-1, 4px)] flex-1">
                                <div className="intertextsmall text-neutral-900">Email</div>
                                <div className="intertextxs text-neutral-500">{userData.email}</div>
                            </div>
                            <div><EditIcon /></div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center gap-2 self-stretch border border-neutral-200 bg-white rounded-2xl">
                        <div className="flex p-[25px] items-center gap-4 flex-1">
                            <div> <img src={key} alt="password" sizes="6" /></div>
                            <div className="flex flex-col justify-center items-start gap-[var(--spacing-1, 4px)] flex-1">
                                <div className="intertextsmall text-neutral-900">Password</div>
                                <div className="intertextxs text-neutral-500">{"............."}</div>
                            </div>
                            <div onClick={toggleForgotPassword}><EditIcon /></div>
                        </div>
                    </div>
                </>
            )}

            {/* Conditionally render ForgotPassword component */}
            {isForgotPasswordVisible && <CreatePasswordComponent showBackButton={false} onPasswordChangeSuccess={handlePasswordChangeSuccess} />}
        </div>
    );
};
