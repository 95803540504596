import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AppDispatch, RootState } from "../../../store/store";
import { editSpendingLimitAsync, spendingAdjustmentsAsync } from "../../../store/thunks/SpendingThunk";
import { categoriesImage } from "../../common/categories";
import BackIcon from "../../../assets/BackIcon";
import "./SpendingLimits.css"; // Import custom CSS for animation
import Ellipse from "./ElipseComponent";
import InfoIcon from "../../../assets/InfoIcon";
import { FinleyCategoryData } from "../../../store/slices/SpendingsSlice";

const SpendingLimits = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const { spendingAdjustmentsResponse, spendingAdjustmentsStatus } = useSelector(
        (state: RootState) => state.spendingStore
    );

    const [spendingAdjustments, setSpendingAdjustments] = useState<FinleyCategoryData[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<FinleyCategoryData | null>(null);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        dispatch(spendingAdjustmentsAsync());
    }, [dispatch]);

    useEffect(() => {
        if (spendingAdjustmentsResponse) {
            setSpendingAdjustments([...spendingAdjustmentsResponse]);
        }
    }, [spendingAdjustmentsResponse]);

    const getIcon = (category: string) => {
        const match = categoriesImage.find(
            (item) => item.categoryName.toUpperCase() === category
        );
        return match ? match.icon : "❓";
    };

    const totalRecommendedAmount = spendingAdjustments.reduce(
        (sum, item) => sum + item.recommendedAmount,
        0
    );

    const handleCategoryClick = (category: FinleyCategoryData) => {
        setSelectedCategory(category);
        setAmount(category.recommendedAmount);
        setShowEditModal(true);
    };

    const handleNumberClick = (num: number) => {
        setAmount((prev) => Number(`${prev}${num}`));
    };

    const handleBackspace = () => {
        setAmount((prev) => Math.floor(prev / 10));
    };

    const handleSave = () => {
        if (selectedCategory) {
            setSpendingAdjustments((prev) =>
                prev.map((item) =>
                    item.finleyCategory === selectedCategory.finleyCategory
                        ? { ...item, recommendedAmount: amount }
                        : item
                )
            );
        }
        closeModal();
    };

    const handleSaveLimits = () => {
        const limits = spendingAdjustments.map((item) => ({
            finley_category: item.finleyCategory,
            amount: item.recommendedAmount,
        }));

        dispatch(editSpendingLimitAsync({ limits }));
    };

    const closeModal = () => {
        setShowEditModal(false);
        setSelectedCategory(null);
    };

    return (
        <div>
            {/* Header Section */}
            <div className="bg-white h-[322px] w-full px-3 pt-14">
                <div className="flex justify-between">
                    <div className="left-0" onClick={() => navigate(-1)}>
                        <BackIcon />
                    </div>
                    <div className="flex text2 justify-center mx-auto">Adjust spending limits</div>
                </div>
                <div className="flex justify-center mt-5">
                    <Ellipse
                        amount={`$${totalRecommendedAmount.toLocaleString()}`}
                        title={"Cutting back by"}
                    />
                </div>
            </div>

            {/* Tile components */}
            <div className="mt-5 px-3">
                {spendingAdjustmentsStatus === "loading" ? (
                    <div>Loading...</div>
                ) : spendingAdjustments.length > 0 ? (
                    spendingAdjustments.map((item, index) => (
                        <div
                            onClick={() => handleCategoryClick(item)}
                            key={index}
                            className="bg-white border border-borderGrey flex rounded-2xl justify-between items-center px-2 h-[76px] mb-2"
                        >
                            <div className="flex items-center">
                                <div className="mr-3 text-2xl">{getIcon(item.finleyCategory)}</div>
                                <div className="text3">{item.finleyCategoryName}</div>
                            </div>
                            <div className="bg-green50 border text4 border-borderSpend text-green h-[36px] w-[80px] flex justify-center items-center rounded-[6px]">
                                $ {item.recommendedAmount.toLocaleString()}
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No data available.</div>
                )}
            </div>

            <div className="fixed bg-white bottom-0 left-0 right-0 h-[92px] pt-3 w-full pb-[25px] px-[25px] border border-t-[1px] border-neutral-200">
                <button
                    className="px-4 py-2 text-white bg-neutral-900 rounded-full text3 w-full"
                    onClick={handleSaveLimits}
                >
                    Save Spending Limits
                </button>
            </div>

            {/* Slide-up Modal */}
            {showEditModal && selectedCategory && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-30 flex items-end"
                    onClick={closeModal}
                >
                    <div
                        className="modal-content slide-up bg-white w-full h-[90%] rounded-t-2xl p-5"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="w-full flex justify-center">
                            <div className="bg-topIcon w-9 h-[3px] rounded-full"></div>
                        </div>
                        <div className="w-full px-3 pt-10">
                            <div className="flex">
                                <div className="flex items-center text-darkBlue text6 justify-center mx-auto pr-6">
                                    {getIcon(selectedCategory.finleyCategory)}
                                    <span className="ml-3 text3">{selectedCategory.finleyCategoryName}</span>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <div
                                    className="flex items-center justify-center"
                                    style={{
                                        width: "177.563px",
                                        height: "178px",
                                    }}
                                >
                                    <div className="flex flex-col text-center">
                                        <span className="text-green intertextxs">Cutting back by</span>
                                        <span className="text-green mt-2 mb-3 texth1">${amount}</span>
                                        <span className="text-green intertextxs">monthly</span>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-infoBg rounded border border-borderGrey flex h-[80px] w-full">
                                <div className="pt-3 pl-1">
                                    <InfoIcon />
                                </div>
                                <div className="text4 text-neutral500 ml-1 mt-2">
                                    I recommend setting your dining out limit to $
                                    {selectedCategory.recommendedAmount} or less, because it will
                                    be less than {selectedCategory.recommendedPercentage}% of your
                                    monthly spending.
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-10 mt-10">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "<"].map((key, index) => (
                                <button
                                    key={index}
                                    className="text6"
                                    onClick={() =>
                                        key === "<"
                                            ? handleBackspace()
                                            : handleNumberClick(Number(key))
                                    }
                                >
                                    {key}
                                </button>
                            ))}
                        </div>
                        <button
                            className="mt-14 bg-black text-white h-10 w-full rounded-full text-lg font-semibold"
                            onClick={handleSave}
                        >
                            <div className="intertextmedium">Save Spending Limits</div>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SpendingLimits;
