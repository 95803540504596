import * as React from 'react';
import { formatCurrency } from '../../../utils/helper';

export interface SpendingHeaderMessageProps {
    monthlyLimit: number;
    remainingLimit: number
}

export function SpendingHeaderMessage ({monthlyLimit,remainingLimit}: SpendingHeaderMessageProps) {
  const displayRemainingLimit = Math.max(remainingLimit, 0);

    return (
    <div className=" ">
     <div className="texth4">{formatCurrency(displayRemainingLimit)} available to spend</div>
     <div className="intertextxs text-neutral-500">of {formatCurrency(monthlyLimit)} available this month</div>
    </div>
  );
}
