import { createSlice } from "@reduxjs/toolkit";
import { editSpendingLimitAsync, fetchCategorySpendsAsync, fetchDailySpendsAsync, spendingAdjustmentsAsync } from "../thunks/SpendingThunk";
import { roundUp } from "../../utils/helper";

export const STATUS = {
    LOADING: "loading",
    SUCCESS: "success",
    IDLE: "idle",
    FAILED: "failed",
};
export const SPENDSTATUS = {
    OVER_SPENT: "OVER_SPENT",
    OVER_THRESHOLD_SPENT: "OVER_THRESHOLD_SPENT",
    UNDER_SPENT: "UNDER_SPENT"
};


export interface DailySpendingData {
    day: number;
    spendingTillDate: number;
}
export interface FetchDailySpendsResponse {
    monthlyLimit: number;
    remainingLimit: number;
    avgDailySpendingLimit: number;
    dailySpending: DailySpendingData[];
}
export interface FinleyCategoryData {
    finleyCategory: string;
    finleyCategoryName: string;
    originalAmount: number;
    recommendedAmount: number;
    recommendedPercentage: number;
    savings: number;
    drasticallyAdjusted: boolean;
}

interface SpendingAdjustmentsState {
    spendingAdjustmentsResponse: FinleyCategoryData[] | null;
    spendingAdjustmentsStatus: string;
    editSpendingResponse: any | null;
    editSpendingStatus: string;
    fetchDailySpendsResponse: FetchDailySpendsResponse | null;
    fetchDailySpendsStatus: string;
    fetchCategorySpentResponse: FinleyCategorySpentData[] | null;
    fetchCategorySpentStatus:string;
    error: string | null;
}

export interface FinleyCategorySpentData {
    finleyCategory: string; 
    finleyCategoryName: string;
    categorySpend: number; 
    spendStatus: string;
    spendPercentage: number; 
    spendRemaining?:number;
}
const initialState: SpendingAdjustmentsState = {
    spendingAdjustmentsResponse: null,
    spendingAdjustmentsStatus: STATUS.IDLE,
    editSpendingResponse: null,
    editSpendingStatus: STATUS.IDLE,
    fetchDailySpendsResponse: null,
    fetchDailySpendsStatus: STATUS.IDLE,
    error: null,
    fetchCategorySpentResponse:  null,
    fetchCategorySpentStatus:  STATUS.IDLE,
};

const SpendingsSlice = createSlice({
    name: "spendingAdjustments",
    initialState,
    reducers: {
        resetSpendingStatus: (state) => {
            state.spendingAdjustmentsStatus = STATUS.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(spendingAdjustmentsAsync.pending, (state) => {
                state.spendingAdjustmentsStatus = STATUS.LOADING;
                state.error = null;
            })
            .addCase(spendingAdjustmentsAsync.fulfilled, (state, action) => {

                //loop throug action.payload and round to 2 decimal originalAmount and recommendedAmount
                state.spendingAdjustmentsResponse = action.payload?.map((item: FinleyCategoryData) => ({
                    ...item,
                    originalAmount: roundUp(item.originalAmount, 2),
                    recommendedAmount: roundUp(item.recommendedAmount, 2),
                    savings: roundUp(item.savings, 2),
                    recommendedPercentage: roundUp(item.recommendedPercentage, 2),
                }));


                state.spendingAdjustmentsStatus = STATUS.SUCCESS;
            })
            .addCase(spendingAdjustmentsAsync.rejected, (state, action) => {
                state.spendingAdjustmentsStatus = STATUS.FAILED;
                state.error = action.error.message ?? "An error occurred.";
            })

            .addCase(editSpendingLimitAsync.pending, (state) => {
                state.editSpendingStatus = STATUS.LOADING;
                state.error = null;
            })
            .addCase(editSpendingLimitAsync.fulfilled, (state, action) => {
                state.editSpendingResponse = action.payload;
                state.editSpendingStatus = STATUS.SUCCESS;
            })
            .addCase(editSpendingLimitAsync.rejected, (state, action) => {
                state.editSpendingStatus = STATUS.FAILED;
                state.error = action.error.message ?? "An error occurred.";
            })

            .addCase(fetchDailySpendsAsync.pending, (state) => {
                state.fetchDailySpendsStatus = STATUS.LOADING;
                state.error = null;
            })
            .addCase(fetchDailySpendsAsync.fulfilled, (state, action) => {
                state.fetchDailySpendsResponse = action.payload
                state.fetchDailySpendsStatus = STATUS.SUCCESS;
            })
            .addCase(fetchDailySpendsAsync.rejected, (state, action) => {
                state.fetchDailySpendsStatus = STATUS.FAILED;
                state.error = action.error.message ?? "An error occurred.";
            })
            .addCase(fetchCategorySpendsAsync.pending, (state) => {
                state.fetchCategorySpentStatus = STATUS.LOADING;
                state.error = null;
            })
            .addCase(fetchCategorySpendsAsync.fulfilled, (state, action) => {
                state.fetchCategorySpentResponse  = action.payload;
                state.fetchCategorySpentStatus = STATUS.SUCCESS;
            })
            .addCase(fetchCategorySpendsAsync.rejected, (state, action) => {
                state.fetchDailySpendsStatus = STATUS.FAILED;
                state.error = action.error.message ?? "An error occurred.";
            });


    },
});

export const { resetSpendingStatus } = SpendingsSlice.actions;
export default SpendingsSlice.reducer;
