import { useEffect } from 'react';
import { App } from '@capacitor/app';
import { useNavigate } from 'react-router-dom';

const useDeepLinking = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleDeepLink = async () => {
      App.addListener('appUrlOpen', (data) => {
        const url = new URL(data.url); // Parse the deep link URL
        const path = url.pathname;

        // Navigate to the corresponding route
        if (path === '/callback') {
          navigate('/new-connect-account');
        } else if (path === '/survey') {
          navigate('/survey');
        }
      });
    };

    handleDeepLink();

    return () => {

      //App.removeAllListeners();
    };
  }, [navigate]); // Ensure the navigate function is part of dependencies
};

export default useDeepLinking;
