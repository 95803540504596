export const roundUp = (number: number, decimalPlaces: number): number => {
    const multiplier = Math.pow(10, decimalPlaces + 1); // Include one extra decimal place
  const nextMultiplier = Math.pow(10, decimalPlaces);

  // Check the third decimal digit
  const extraDigit = Math.floor(number * multiplier) % 10;

  if (extraDigit > 5) {
    // Round up if the third decimal digit is greater than 5
    return Math.ceil(number * nextMultiplier) / nextMultiplier;
  } else {
    // Otherwise, round normally to the specified decimal places
    return Math.floor(number * nextMultiplier) / nextMultiplier;
  }
  };
  export const formatCurrency = (amount: number) =>
    
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
