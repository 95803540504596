import { createAsyncThunk } from "@reduxjs/toolkit";
import { createPassword, login, resetPassword} from "../../services/AuthService";


export const loginUserAsync = createAsyncThunk(
  "user/Login",
  async (data: any, thunkAPI) => {
    const response = await login(data);
    return response;
  }
);



export const resetPasswordAsync = createAsyncThunk(
  "user/ResetPassword",
  async (data: any, thunkAPI) => {
    const response = await resetPassword(data);
    return response;
  }
);



export const createPasswordAsync = createAsyncThunk(
  "user/CreatePassword",
  async (data: { password: string; confirmPassword: string; token: string }, thunkAPI) => {
      const { password, confirmPassword, token } = data;
      const response = await createPassword({ password, confirmPassword }, token);
      return response;
  }
);

