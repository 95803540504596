import React from "react";
import { Dialog, DialogPanel, Transition } from "@headlessui/react";

interface ActionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAction: () => void; // Generic callback for the action button
  cancelText?: string; // Optional custom text for the Cancel button
  actionText?: string; // Optional custom text for the Action button
  message: string; // Customizable message for the modal
}

const ActionModal: React.FC<ActionModalProps> = ({
  isOpen,
  onClose,
  onAction,
  cancelText = "Cancel", 
  actionText = "Confirm", 
  message, // Customizable message text
}) => {
  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10 w-full h-full" onClose={onClose}>
        {/* Overlay */}
        <div className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="fixed inset-0 flex justify-center items-center">
          <div className="flex w-full justify-center items-center">
            {/* Modal content wrapper with adjusted size */}
            <Transition.Child
              as={React.Fragment}
              enter="transition transform ease-out duration-300"
              enterFrom="translate-y-[-100%]" // Start from the top of the screen
              enterTo="translate-y-0" // End at the center
              leave="transition transform ease-in duration-300"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-[-100%]" // Slide up back out of the screen
            >
              <div className="w-3/4 bg-white rounded-lg shadow-xl flex flex-col justify-center items-center">
                {/* Content Area */}
                <div className="text-center text-xs w-full pt-4">
                  <h3 className="text-sm font-semibold mb-3">{message}</h3> {/* Custom message */}
                  <div className="flex justify-center w-full border-t border-neutral-200">
                    {/* Cancel button with full height right border */}
                    <div className="flex-1 flex justify-center items-center border-r border-neutral-200 h-full pb-3">
                      <button
                        className="carouselSubTitle py-1 px-2"
                        onClick={onClose}
                      >
                        {cancelText} {/* Dynamic Cancel text */}
                      </button>
                    </div>

                    {/* Action button with full height right border */}
                    <div className="flex-1 flex justify-center items-center h-full">
                      <button
                        className="carouselSubTitle py-1 px-2"
                        onClick={onAction}
                      >
                        {actionText} {/* Dynamic Action text */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ActionModal;
