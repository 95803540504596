

import React from "react";
import ReactApexChart from "react-apexcharts";

interface MonthlyData {
  day: number;
  spendingTillDate?: number | null;
  spendingLimitTillDate: number;
}

interface LineChartProps {
  monthlyData: MonthlyData[];
}

const chartOptions: ApexCharts.ApexOptions = {
  chart: {
    type: "line",
    toolbar: {
      show: false, // Hides the toolbar completely
    },
    height: "68px",
    background: "#ffffff",
    dropShadow: {
      enabled: true,
      top: 3,
      left: 3,
      blur: 5,
      opacity: 0.1,
    },
    zoom: {
      enabled: false, // Disable zoom functionality
    },
  },
  stroke: {
    curve: "straight",
    lineCap: "butt",
    dashArray: [0, 6], // Solid line for "Spending Till Date", dashed for "Spending Limit Till Date"
    width: 5,
  },
  grid: {
    show: false, // Disable gridlines
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled:false,
 
  },
  xaxis: {
    categories: [], // Will be populated dynamically
    labels: {
      show: false,
    },
    title: {
      text: "",
    },
    axisBorder: {
      show: false
     
    },
    axisTicks:{
      show:false
    }
  },
  yaxis: {
    title: {
      text: "",
    },
    labels: {
      show: false,
    },
  },
  colors: ["#00E396", "#D4D4D4"], // Colors for the two lines
  legend: {
    show: false,
  },
  
};

const LineChart: React.FC<LineChartProps> = ({ monthlyData }) => {

  
  // Filter out days where spendingTillDate is null or undefined
  const spendingfilteredData = monthlyData.filter((data) => data.spendingTillDate !== undefined && data.spendingTillDate !== null);

const numberOfDays = monthlyData.length;

// Generate dashArray dynamically based on the number of days
const dashArray = Array(numberOfDays).fill(6); // Set dash length to 6, repeat for each day

  const chartSeries: ApexAxisChartSeries = [
    {
      name: "Spending Till Date",
      data: spendingfilteredData.map((data) => ({
        x: data.day.toString(), 
        y: data.spendingTillDate ?? 0, 
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical', 
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 1,
            // colorStops: [
            //   {
            //     offset: 0, // Start with green color at 0%
            //     color: "#22C55E", // Green color
            //     opacity: 0.7,
            //   },
            //   {
            //     offset: 50, // Transition to yellow at 50%
            //     color: "#FFD900", // Yellow color
            //     opacity: 0.7,
            //   },
            //   {
            //     offset: 100, // End with yellow at 100%
            //     color: "#FFD900", // Keep yellow at the end
            //     opacity: 0.7,
            //   },
            //],
          },
        },
      })),
    },
    {
      name: "Spending Limit Till Date",
      data: monthlyData.map((data) => ({
        x: data.day.toString(), // Using the day as x-axis
        y: data.spendingLimitTillDate,
        fill: {
          type: 'solid', // No gradient, just a solid color
          color: '#D4D4D4', // Solid color for the "Spending Limit Till Date" line
        },
        stroke: {
          curve: "straight",
          lineCap: "butt",
          dashArray: dashArray, // Solid line for "Spending Till Date", dashed for "Spending Limit Till Date"
          width: 3,
        },
      })),
    },
  ];
  
  

  const chartOptionsWithCategories = {
    ...chartOptions,
    xaxis: {
      ...chartOptions.xaxis,
      categories: monthlyData.map((data) => data.day.toString()), // Set categories to days
    },
  };
 
  return (
    <div style={{maxHeight:'190px'}} className="w-full overflow-x-auto hide-scrollbar">
 

        <ReactApexChart
          options={chartOptionsWithCategories}
          series={chartSeries}
          type="line"
          height={"180px"}
          style={{padding:'0px', margin:'0px'}}
         
        />
    
      </div>
 
  );
};

export default LineChart;
