import './App.css'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import {Main} from './components/common/Main';
import {useEffect, useState} from 'react';
import { SafeArea } from 'capacitor-plugin-safe-area';

function App() {

  const [appInsets, setAppinsets] = useState({top: 0, bottom:0})
 
  useEffect(() => {
    (async function(){
        const safeAreaData = await SafeArea.getSafeAreaInsets();
        const {insets} = safeAreaData;
        for (const [key, value] of Object.entries(insets)) {
            document.documentElement.style.setProperty(
                `--safe-area-inset-${key}`,
                `${value}px`,
            );
        }
    })()
}, []);
  useEffect(() => {
    const setupWithInsets = async function () {
      const insets = await SafeArea.getSafeAreaInsets()
      appInsets.top = insets.insets.top;
      appInsets.bottom = insets.insets.bottom
      setAppinsets({...appInsets})
    } 
    setupWithInsets();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
      <div className='pb-safe toolbar'>
        <Main/>
        </div>
      </BrowserRouter>
    </Provider >
  );
}
export default App;
