import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDailySpends, editSpendingLimitService, spendingAdjustment, fetchCategorySpends } from "../../services/SpendingService";


export const spendingAdjustmentsAsync = createAsyncThunk(
  "user/spendAdjustments",
  async ( thunkAPI) => {
    const response = await spendingAdjustment();
    return response;
  }
);


export const editSpendingLimitAsync = createAsyncThunk(
  "user/editSpends",
  async (data: any, thunkAPI) => {
    const response = await editSpendingLimitService(data);
    return response;
  }
);


export const fetchDailySpendsAsync = createAsyncThunk(
  "user/dailySpends",
  async ( thunkAPI) => {
    const response = await fetchDailySpends();
    return response;
  }
);

export const fetchCategorySpendsAsync = createAsyncThunk(
  "user/fetchCategorySpent",
  async (thunkAPI) => {
    const response = await fetchCategorySpends();  
    return  response;
  }
);