import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './slices/AuthSlice'
import TransactionReducer from './slices/TransactionSlice'
import { OnboardingReducer } from './slices/OnBoardingSlice'
import SubmitSurveyReducer from './slices/submitSurveySlice';
import MasterReducer from './slices/MasterSlice';
import ChatReducer from './slices/ChatSlice';
import SpendingAdjustmentsReducer from './slices/SpendingsSlice';
export const store = configureStore({
  reducer: {
    AuthStore: AuthReducer,
    TransactionStore: TransactionReducer,
    OnboardingStore: OnboardingReducer,
    SurveyStore: SubmitSurveyReducer,
    ChatStore: ChatReducer,
    MasterStore: MasterReducer,
    spendingStore:SpendingAdjustmentsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


