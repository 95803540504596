import { roundUp } from "../../../utils/helper";

export const SpendingLimitTitleMessage = ({
  overSpendingAmount,
  recommendedAmount,
}: {
  overSpendingAmount: number;
  recommendedAmount: number;
}) => {

  const displayAmount =
    recommendedAmount < 0
      ? `-$${Math.abs(roundUp(recommendedAmount,2))}`
      : `$${roundUp(recommendedAmount,2)}`;

  // If recommendedAmount is negative, return the specific message
  if (recommendedAmount < 0) {
    return (
      <div>
        Your spending limits are too high, please adjust to continue or skip for now.
      </div>
    );
  }

  // Only check for overspending or underspending if recommendedAmount is positive
  if (recommendedAmount > 0) {
    const isOverspending = overSpendingAmount < 0;

    return (
      <div>
        {isOverspending ? (
          <div>
            I found a few adjustments that will reduce your spending by {displayAmount}.
          </div>
        ) : (
          <div>
            I found a few adjustments that will increase your cash flow by {displayAmount}.
          </div>
        )}
      </div>
    );
  }

  return null; // This will never be reached, but just a fallback
};
