import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountAcknowledgeAsync, ResendOtpAsync, SaveAccountAsync, VerifyEmailAsync, VerifyOtpAsync } from '../thunks/OnBoardingThunk';
import { HttpStatus } from '../../types/CommonType';

// Enums for status and loading states
export enum OnboardingState {
  NONE = 'NONE',
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  BANK_ACCOUNT_CONNECTED = 'BANK_ACCOUNT_CONNECTED',
  ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
  PENDING = 'PENDING'
}
// Defining possible status values
export const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  IDLE: "idle",
  FAILED: "failed",
};
export enum OtpStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending'
}

export enum AccountCreationStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending'
}

export enum AcknowledgeStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending'
}

export enum LoadingState {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed'
}


// Interface for UserOnboarding details
interface UserOnboarding {
  msg: string;
  first: string;
  last: string;
  email: string;
  accessToken: string;
  onboarding_state: null|string,
  terms_accepted_at:null|string

}
export interface validationStep
  {
    step1: boolean ;
    step2: boolean;
    step3: boolean; 
    step4: boolean;
  }


// Interface for the FormState
interface FormState {
  email: string;
  code: string;
  phone: string;
  password: string;
  currentStep: number;
  isStepValid: boolean;
  isValid:validationStep
  userOnboarding: UserOnboarding;
  loadingState: LoadingState; // Loading state for API calls
  error: string | null; // Error message in case of failure
  emailVerificationStatusCode: 200 | 401 | 409 | undefined;
  otpStatus: OtpStatus;
  emailVerificationStatus: string;
  otpVerification: OtpStatus;
  accountCreationStatus: AccountCreationStatus;
  acknowledgeStatus: AcknowledgeStatus;
  acknowledge:TERMS_AND_PRIVACY
}

interface TERMS_AND_PRIVACY
{
  termsDate:Date | undefined,
  privacypolicyDate: Date | undefined,
}

// Initial state for the form
const initialState: FormState = {
  email: '',
  code: '',
  phone: '',
  password: '',
  currentStep: 1,
  isStepValid: false,
  isValid: {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  },
  userOnboarding: {
    msg: '',
    first: '',
    last: '',
    email: '',
    accessToken: '',
    onboarding_state:'',
    terms_accepted_at:null
  },
  otpStatus: OtpStatus.PENDING,
  accountCreationStatus: AccountCreationStatus.PENDING,
  acknowledgeStatus: AcknowledgeStatus.PENDING,
  acknowledge:{
    termsDate: undefined,
    privacypolicyDate: undefined,
  },
  loadingState: LoadingState.IDLE, // Initial API call state
  error: null,
  emailVerificationStatusCode: undefined,
  emailVerificationStatus: '',
  otpVerification: OtpStatus.PENDING
  
};

// Create the onboarding slice
const OnBoardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {

    updateFormData(state, action: PayloadAction<Partial<FormState>>) {
       return { ...state, ...action.payload };
    },
    resetEmailVerificationStatusCode(state) {
      state.emailVerificationStatusCode = undefined;
    },
    updateAcknowledgeDate(state, action: PayloadAction<{ field: 'termsDate' | 'privacypolicyDate'; value: Date | undefined }>) {
      state.acknowledge[action.payload.field] = action.payload.value;
    },
    validateStep: (state, action: PayloadAction<{ step: keyof FormState['isValid']; isValid: boolean }>) => {

      state.isValid[action.payload.step] = action.payload.isValid; 
    },
    goToNextStep(state) {
      // Only allow going to the next step if the current step is valid
      if (state.isStepValid && state.currentStep < 4) {
        state.currentStep += 1;
        state.isStepValid = false; // Reset validity for the next step
      }
    },
    goToPrevStep(state) {
      if (state.currentStep > 1) {
        state.currentStep -= 1;
        state.isStepValid = true; // Assume validity when going back
      }
    },
    resetOnboardingState() {
      return initialState; // Reset the state to the initial state
    },
    setStepValid(state, action: PayloadAction<boolean>) {
      state.isStepValid = action.payload; // Set step validity based on payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(VerifyEmailAsync.pending, (state) => {
        state.loadingState = LoadingState.LOADING; // Set to loading state
        state.error = null; // Reset error state
        state.emailVerificationStatusCode = undefined;
      })
      .addCase(VerifyEmailAsync.fulfilled, (state, action) => {
        state.loadingState = LoadingState.SUCCESS; // Set to success
        state.error = null; // Clear any previous error
        state.emailVerificationStatusCode = 200;
      })
      .addCase(VerifyEmailAsync.rejected, (state, action: any) => {
        state.loadingState = LoadingState.FAILED; // Set to failed
        const statusCode = action.payload.status;
        state.emailVerificationStatusCode = statusCode;
        const statusKey = Object.keys(HttpStatus).find(key => HttpStatus[key as keyof typeof HttpStatus] === statusCode);

        if (statusKey) {
          state.emailVerificationStatus = statusKey;
        } else {
          state.emailVerificationStatus = 'Unknown';  // Fallback if statusCode is not recognized
        }
      })

      .addCase(VerifyOtpAsync.pending, (state) => {
        state.loadingState = LoadingState.LOADING;
        state.error = null;
        state.otpStatus = OtpStatus.PENDING
      })
      .addCase(VerifyOtpAsync.fulfilled, (state, action) => {
        state.otpStatus = OtpStatus.SUCCESS;
        state.loadingState = LoadingState.SUCCESS;
        state.userOnboarding = action.payload;
        state.error = null;
      })
      .addCase(VerifyOtpAsync.rejected, (state, action) => {
        state.otpStatus = OtpStatus.FAILED;
        state.loadingState = LoadingState.FAILED;
        state.error =  'Invalid OTP';
      })

      .addCase(SaveAccountAsync.pending, (state) => {
        state.loadingState = LoadingState.LOADING;
        state.accountCreationStatus = AccountCreationStatus.PENDING
        state.error = null;
      })
      .addCase(SaveAccountAsync.fulfilled, (state, action) => {
      
        state.loadingState = LoadingState.SUCCESS;
        state.accountCreationStatus = AccountCreationStatus.SUCCESS
        state.userOnboarding = action.payload;
        state.error = null;
        
      })
      .addCase(SaveAccountAsync.rejected, (state, action) => {
        state.loadingState = LoadingState.FAILED;
        state.accountCreationStatus = AccountCreationStatus.FAILED;
        state.error = action.payload as string || 'Account creation failed';
      })

      .addCase(AccountAcknowledgeAsync.pending, (state) => {
        state.loadingState = LoadingState.LOADING;
        state.error = null;
      })
      .addCase(AccountAcknowledgeAsync.fulfilled, (state, action) => {
      
        state.loadingState = LoadingState.SUCCESS;
        state.error = null;
      })
      .addCase(AccountAcknowledgeAsync.rejected, (state, action) => {
        state.acknowledgeStatus = AcknowledgeStatus.FAILED;
        state.loadingState = LoadingState.FAILED;
        state.error = action.payload as string ;
      })

      .addCase(ResendOtpAsync.pending, (state) => {
        state.loadingState = LoadingState.LOADING;
        state.error = null;
      })
      .addCase(ResendOtpAsync.fulfilled, (state, action) => {
        state.otpStatus = OtpStatus.PENDING;
        state.loadingState = LoadingState.SUCCESS;
        state.error = null;
      })
      .addCase(ResendOtpAsync.rejected, (state, action) => {
        state.loadingState = LoadingState.FAILED;
        state.error = action.payload as string || 'Resending OTP failed';
      });
  },
});

export const { updateFormData, updateAcknowledgeDate, resetEmailVerificationStatusCode, goToNextStep, goToPrevStep, setStepValid, validateStep, resetOnboardingState } = OnBoardingSlice.actions;
export const OnboardingReducer = OnBoardingSlice.reducer;


