import  { FC } from "react";
import DrawerComponent from "../../common/DrawerComponent";
import MessageComponent from "../../common/MessageComponent";
import KeypadBackIcon from "../../../assets/KeypadBackIcon";
import { roundUp } from "../../../utils/helper";

type EditModalProps = {
    isVisible: boolean;
    category: any;
    amount: number;
    onNumberClick: (num: number) => void;
    onBackspace: () => void;
    onSave: () => void;
    onClose: () => void;
    getIcon: (category: string) => string;
    originalAmount:number;
};

const EditModal: FC<EditModalProps> = ({
    isVisible,
    category,
    amount,
    onNumberClick,
    onBackspace,
    onSave,
    onClose,
    getIcon,
    originalAmount
}) => {
    if (!category) return null;
   
    const title = (
        <div className="flex items-center text-darkBlue text6 justify-center mx-auto pr-6">
            {getIcon(category.finleyCategory)}
            <span className="ml-3 text3">{category.finleyCategoryName}</span>
        </div>
    );

    const content = (
        <div>
            <div className="w-full px-3">
                <div className="flex justify-center">
                    <div
                        className="flex items-center justify-center"
                        
                    >
                        <div className="flex flex-col text-center">
                            <span className="text-green intertextmediumparagraph">Cutting back by</span>
                            <span className="text-green mt-2 mb-2 texth1">${(amount)}</span>
                            <span className="text-green intertextmediumparagraph">monthly</span>
                        </div>
                    </div>
                </div>
                <div className="mt-[34px]">
                    <MessageComponent 
                        type="Info" 
                        message={
                            <div>
                                I recommend setting your {category.finleyCategoryName} limit to $
                                {roundUp(originalAmount,2)} or less because it will be
                                less than {roundUp(category.recommendedPercentage,2)}% of your monthly spending.
                            </div>
                        } 
                    />
                </div>
            
            </div>
            <div className="grid grid-cols-3 gap-10 mt-[34px]">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "<"].map((key:any, index) => (
                    <button
                        key={index}
                        className="text6 flex justify-center items-center"
                  onClick={() => {
                if (key === "<") {
                    onBackspace();
                } else {
                    onNumberClick(key);
                }
            }}
                    >
                         {key === "<" ? <KeypadBackIcon /> : key}
                    </button>
                ))}
            </div>
            <button
                className="mt-[34px] bg-black text-white h-10 w-full rounded-full text-lg font-semibold"
                onClick={onSave}
            >
                Save
            </button>
        </div>
    );

    return (
        <DrawerComponent
            isOpen={isVisible}
            onClose={onClose}
            title={title}
            content={content}
        />
    );
};

export default EditModal;
