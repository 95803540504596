import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { getUserAccountDetailsAsync } from '../../../store/thunks/MasterThunk';
import carouselImage from "../../../assets/carouselHome.svg";
import ChatInput from '../../common/ChatInput';
import { setChatMessage } from '../../../store/slices/ChatSlice';
import { Button } from '@headlessui/react';
import { ArrowRight } from 'lucide-react';
import { fetchAllTransactionsAsync } from '../../../store/thunks/TransactionThunk';
import { useNavigate } from 'react-router';
import GreetingHeader from '../../common/GreetingHeader';

export interface HomeTabSectionProps {
    selectTab: (tabName: string) => void;
}

export function HomeTabSection({ selectTab }: HomeTabSectionProps) {
    const buttonLabels = [
        'Boost my savings',
        'Help me budget',
        'Cut my spending',
        'Show my spending this month'
    ];
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();


    useEffect(() => {
        const fetchTransactionsData = async () => {
            await dispatch(fetchAllTransactionsAsync());
        };
        fetchTransactionsData();
    }, [dispatch]);


    const handleSend = (message: string) => {
        dispatch(setChatMessage(message));
        selectTab('Finley AI');
    };

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getUserAccountDetailsAsync());
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        const fetchTransactionsData = async () => {
            await dispatch(fetchAllTransactionsAsync());
        };
        fetchTransactionsData();
    }, [dispatch]);

 
    return (
        <div className="flex flex-col h-screen" >
            {/* Sticky Header */}
            <GreetingHeader />
          

            {/* Main Content Section with Scroll */}
            <main className="flex-1   px-3 ">
                <div className="py-3">
                    <ChatInput isLoading={false} send={handleSend} />
                </div>

                <div className="w-full overflow-x-auto hide-scrollbar">
                    <div className="flex space-x-1">
                        {buttonLabels.map((label, index) => (
                            <div key={index} className="flex-shrink-0">
                                <Button
                                    onClick={() => handleSend(label)}
                                    className="px-4 py-2 border-[0.5px] border-solid text-darkBlue bg-white rounded-full text3 border-neutral-300"
                                >
                                    {label}
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex mt-3 p-[25px] h-[452px] flex-col gap-[25px] self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white">
                    {/* Content above the button */}
                    <div>
                        <div className="flex pb-[25px] justify-center h-[224px]">
                            <img src={carouselImage} alt="home" />
                        </div>
                        <div className="text2">Let’s find you some money.</div>
                        <div className="pt-3 text3">
                            You can free up extra money in your budget with simple steps—it only takes 5 minutes.
                        </div>
                    </div>

                    {/* Button inside scrollable content */}
                    <div className="w-full">
                        <button
                            onClick={() => navigate('/new-connect-account')}
                            className="flex w-full items-center justify-center px-4 py-2 bg-neutral-900 text-white rounded-full space-x-1 text3"
                        >
                            <ArrowRight size={16} />
                            <span>Analyze my spending</span>
                        </button>
                    </div>
                </div>
            </main>
         
        </div>
    );
}
