import React, { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
import BackIcon from "../../../assets/BackIcon";
import { useNavigate, useParams } from "react-router";
import coachesData from "./CoachInfo.json";

const SchedulingMeeting: React.FC = () => {
    const { coachId } = useParams();
    const navigate = useNavigate();
    const [coachUrl, setCoachUrl] = useState<string | null>(null);

    useEffect(() => {
        if (coachId) {
            const coach = coachesData.find((coach) => coach.CoachId === coachId);
            if (coach) {
                setCoachUrl(coach.url);
            }
        }
    }, [coachId]);
    return (
        <div className="pt-16">
            <div className="flex pb-5 ">
               <div onClick={()=>{navigate('/home?from=Meet Coach')}}> <BackIcon /></div>
                <div className="carouselTitle mx-auto">Book your session</div>
            </div>
            <div className="container h-screen w-full ">
                <InlineWidget

                    url={coachUrl || ''}
                    styles={{ height: "100%", width: "100%" }}
                />
            </div>
        </div>
    );
};

export default SchedulingMeeting;
