import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('.app').pop();
      if (slug) {
        navigate(slug);
      }
    });
  }, [navigate]);

  return null;
};

export default AppUrlListener;
