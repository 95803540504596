
// import { Button } from '@headlessui/react'
// import { PlusIcon } from '@heroicons/react/20/solid';
// import React, { useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import { AppDispatch, RootState } from '../../../store/store';
// import { deleteFinancialAccountItemsAsync, fetchAccountDetailAsync, fetchFinancialAccountItemsAsync } from '../../../store/thunks/TransactionThunk';
// import { fetchFinancialAccountItems } from '../../../services/TransactionService';
// import { BanknoteIcon, MinusSquare } from 'lucide-react';
// import PlaidLinkComponent from '../../plaid/PlaidLinkComponent';
// import minus from '../../../assets/minus.svg'



// const FinancialAccountConnect = () => {
//   const dispatch = useDispatch<AppDispatch>();
//   let financialAccounts = useSelector((state: RootState) => state.TransactionStore.financialAccounts);
//   const { accounts } = useSelector((state: RootState) => state.TransactionStore);
// financialAccounts=[
//   {
//       "id": 216,
//       "institution": {
//           "id": 5,
//           "logoContent": null,
//           "institutionName": "https://www.chase.com",
//           "institutionId": "ins_56",
//           "url": "https://www.chase.com"
//       },
//       "userId": "38beb382-a9d0-4bb6-9c22-684bdd4095ba",
//       "loginRequired": false,
//       "consentExpirationTime": null
//   }
// ]

//   useEffect(() => {
//     const fetchAccounts = async () => {
//       await dispatch(fetchFinancialAccountItemsAsync());
//     };

//     fetchAccounts();
//   }, [dispatch]);
//   useEffect(() => {
//     const fetchAccounts = async () => {
//       await dispatch(fetchAccountDetailAsync());
//     };

//     fetchAccounts();
//   }, [dispatch]);
//   const handleDelete = async(itemId:number)=>{
//     await dispatch(deleteFinancialAccountItemsAsync(itemId));
//   }

//   return (
//     <div className="flex flex-col items-start gap-3 pt-5" >
//       <div className="flex flex-col items-start gap-2 self-stretch">
//         <div className="flex flex-col items-start gap-6 self-stretch p-6 rounded-2xl border border-slate-200 bg-white">
//           <div className="flex flex-col items-start gap-4 self-stretch">
//             <div className="flex justify-between items-start self-stretch">
//               <div className='textlarge700'>Manage</div>
//               <div className="rounded-lg borderbg-customGray  ">
           
//                  <PlaidLinkComponent buttonClassName='p-2  rounded-lg border border-neutral-200' divClassName="flex justify-center items-center " plusIconClassName='h-4 w-4  ' />
//               </div>
//             </div>
     
//              {accounts?.map((account) => (
//               <div key={account.id} className="flex items-center gap-3 self-stretch">
//                 <div className="flex p-3 items-center gap-3 flex-1 self-stretch bg-neutral-100 rounded-lg">
//                   {/* Logo Image */}
//                   {account.institution?.logo_content ? (
//                       <img 
//                         src={`data:image/png;base64,${account.institution.logo_content}`}
//                         alt={`${account.institution.institution_name} logo`}
//                         className="size-9"
//                       />
//                     ) : (
//                       <BanknoteIcon className="text-customBlue size-9"></BanknoteIcon>
//                     )}
//                   {/* Institution Name */}
//                   <div className="flex flex-col items-start">
//                     <div className="carouselSubTitle">
//                       {account.official_name}
//                     </div>
//                     <div className="intertextxs text-neutral-500"> ... {account.mask}</div> 
//                   </div>
//                 </div>
//                 <div><Button className="rounded-lg border border-neutral-200 bg-white p-2" onClick={()=>handleDelete(account.id)}><img src={minus}></img></Button></div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div >
//   )
// }

// export default FinancialAccountConnect
import React, { useState, useEffect } from 'react';
import { Button } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { deleteFinancialAccountItemsAsync, fetchAccountDetailAsync, fetchFinancialAccountItemsAsync } from '../../../store/thunks/TransactionThunk';
import PlaidLinkComponent from '../../plaid/PlaidLinkComponent';
import { BanknoteIcon } from 'lucide-react';
import { MinusSquare } from 'lucide-react';
import minus from '../../../assets/minus.svg';
import ActionModal from '../../common/ActionModal'; // Import the ActionModal

const FinancialAccountConnect = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accounts = useSelector((state: RootState) => state.TransactionStore.accounts);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState<string | null>(null);

  // Fetch financial accounts and details

  useEffect(() => {
    const fetchAccounts = async () => {
      await dispatch(fetchAccountDetailAsync());
    };
    fetchAccounts();
  }, [dispatch]);

  // Handle delete action
  const handleDelete = async (itemId: string) => {
    // Set the account ID to be deleted and open the modal
    setAccountToDelete(itemId);
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (accountToDelete !== null) {
      await dispatch(deleteFinancialAccountItemsAsync(accountToDelete));
      await dispatch(fetchAccountDetailAsync());
      setIsModalOpen(false);
    }
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col items-start gap-3 pt-5 " >
      <div className="flex flex-col items-start gap-2 self-stretch ">
        <div className="flex flex-col items-start gap-6 self-stretch p-6 rounded-2xl border border-slate-200 bg-white 0">
          <div className="flex flex-col items-start gap-4 self-stretch" >
            <div className="flex justify-between items-start self-stretch ">
              <div className="textlarge700">Manage</div>
              <div className="rounded-lg borderbg-customGray">
                <PlaidLinkComponent buttonClassName='p-2 rounded-lg border border-neutral-200' divClassName="flex justify-center items-center " plusIconClassName='h-4 w-4' />
              </div>
            </div>

            {accounts?.map((account) => (
              <div key={account.id} className="flex items-center gap-3 self-stretch">
                <div className="flex p-3 items-center gap-3 flex-1 self-stretch bg-neutral-100 rounded-lg">
                  {/* Logo Image */}
                  {account.institution?.logo_content ? (
                    <img 
                      src={`data:image/png;base64,${account.institution.logo_content}`}
                      alt={`${account.institution.institution_name} logo`}
                      className="size-9"
                    />
                  ) : (
                    <BanknoteIcon className="text-customBlue size-9" />
                  )}
                  {/* Institution Name */}
                  <div className="flex flex-col items-start">
                    <div className="carouselSubTitle">
                      {account.official_name} <span className="intertextxs text-neutral-500">(... {account.mask})</span>
                     
                    </div>
                   
                  </div>
                </div>
                {/* Delete Button */}
                <div>
                  <Button className="rounded-lg border border-neutral-200 bg-white p-2" onClick={() => handleDelete(account.item_id)}>
                    <img src={minus} alt="Delete" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Action Modal for delete confirmation */}
      <ActionModal
        isOpen={isModalOpen}
        onClose={cancelDelete}
        onAction={confirmDelete}
        message="Are you sure you want to delete this account?"
        cancelText="Cancel"
        actionText="Delete"
      />
    </div>
  );
};

export default FinancialAccountConnect;
