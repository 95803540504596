// import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { useEffect, useState } from "react";
import Chat from "../chat/Chat";
import { HomeTabSection } from "./HomeTabSection";
import  SpendingHome  from "../Spendings/SpendingHome";
import CoachingPageHome from "../CoachingHome/CoachingPageHome";
import { useSearchParams } from "react-router-dom";

const Home = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const from = searchParams.get('from');
    
    const [activeTab, setActiveTab] = useState('Home'); // Track the active tab
    const selectTab = (tabName: string) => {

        setActiveTab(tabName);
    }
    useEffect(() => {
        if (from) {
            setActiveTab(from);
            searchParams.delete('from');
            setSearchParams(searchParams);
        }
    }, [from]);
    return (
        <div className='flex flex-col'>
            <div className="flex flex-col ">
              
                {activeTab !== 'Finley AI' && (
                    <div className="max-h-screen">
                        {/* Add your main content here */}
                        {activeTab === 'Home' && <div className='pt-3'> <HomeTabSection selectTab={selectTab} ></HomeTabSection></div>}
                        {activeTab === 'Spending' && <div className='pt-3'><SpendingHome selectTab={selectTab}></SpendingHome></div>}
                        {activeTab === 'Meet Coach' && <div className='pt-3'> <CoachingPageHome selectTab={selectTab} ></CoachingPageHome></div>}
                    </div>
                )}
                {activeTab === 'Finley AI' && <Chat isLoadedFromHomeScreen={true} selectTab={selectTab} />}

                <footer className="sticky bottom-0">
                    {activeTab !== 'Finley AI' && (
                        <Footer activeTab={activeTab}  selectTab={selectTab} />

                    )}</footer>
            </div>
        </div>
    );
};

export default Home;
