import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { createPasswordAsync } from "../../../store/thunks/AuthThunk";
import Header from "../../common/Header";
import BackIcon from "../../../assets/BackIcon";
import MessageComponent from "../../common/MessageComponent";
import PasswordInput from "../../common/PasswordInput";
import { STATUS } from "../../../store/slices/AuthSlice";

interface CreatePasswordState {
    password: string;
    confirmPassword: string;
}

interface CreatePasswordProps {
    showBackButton?: boolean;
    navigateTo?: string; // optional prop to conditionally change the navigation after password reset
    onPasswordChangeSuccess?: () => void; // Callback to notify parent of success
}

const CreatePasswordComponent: React.FC<CreatePasswordProps> = ({
    showBackButton = true,
    navigateTo = "/login",
    onPasswordChangeSuccess, 
}) => {
    const navigate = useNavigate();
    const [createPasswordState, setCreatePasswordState] = useState<CreatePasswordState>({
        password: '',
        confirmPassword: '',
    });
    const [alertMessage, setAlert] = useState<{
        message: string;
        type: 'success' | 'error' | null;
        title: string;
    }>({
        message: '',
        type: null,
        title: '',
    });
    const [samePassword, setSamePassword] = useState<Boolean | null>(null);
    const [type, setType] = useState<'Info' | 'Error' | 'Success'>('Info');
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const resetPasswordStatus = useSelector((state: RootState) => state.AuthStore.createPasswordStatus);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        setAccessToken(token);
    }, []);

    useEffect(() => {
        if (resetPasswordStatus === STATUS.SUCCESS) {

            //localStorage.removeItem('accessToken');

            // Navigate to the passed route or default ("/login")
            if (onPasswordChangeSuccess) {
                onPasswordChangeSuccess();
            }
            else
            {
                navigate('/login')
            }
        
        } else if (resetPasswordStatus === STATUS.FAILED) {
            setAlert({ message: 'Reset password failed, please try again later', type: "error", title: 'Reset Password' });
        }
    }, [resetPasswordStatus, navigate, navigateTo]);

    const validatePassword = (password: string): boolean => {
        const minLength = 12;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return (
            password.length >= minLength &&
            hasUpperCase &&
            hasLowerCase &&
            hasNumber &&
            hasSpecialChar
        );
    };

    const getPasswordValidationClass = (password: string) => {
        if (validatePassword(password)) {
            setType("Success");
        } else {
            setType("Error");
        }
    };

    const samePasswordCheck = (field: 'password' | 'confirmPassword', value: string) => {
        setCreatePasswordState((prevState) => ({
            ...prevState,
            [field]: value,
        }));

        if (field === 'confirmPassword') {
            setSamePassword(createPasswordState.password !== value);
        } else if (field === 'password' && createPasswordState.confirmPassword) {
            setSamePassword(value !== createPasswordState.confirmPassword);
        }
    };

    const handleCreatePassword = async () => {
        const { password, confirmPassword } = createPasswordState;
        const token = accessToken || localStorage.getItem('accessToken') || '';

        await dispatch(createPasswordAsync({ password, confirmPassword, token }));
    };

    const isButtonDisabled = !(
        validatePassword(createPasswordState.password) &&
        createPasswordState.confirmPassword &&
        !samePassword
    );

    const renderMessage = () => {
        if (!alertMessage.message) return null;
        const messageClass = alertMessage.type === "success" ? "text-green-500" : "text-red-500";
        return <div className={`${messageClass} mt-2 text-center`}>{alertMessage.message}</div>;
    };

    return (
        <div className="flex flex-col pt-3 px-2.5 h-screen">
            {showBackButton && (
                <div onClick={() => navigate('/')}>
                    <BackIcon />
                </div>
            )}
            <div className="mt-28">
                <Header />
            </div>
            <div className="texth2 flex justify-center">Create new password.</div>

            <div className="carouselSubTitle flex justify-start items-start mt-6 ml-1">
                Create password
            </div>

            <div>
                <PasswordInput
                    password={createPasswordState.password}
                    setPassword={(value) => {
                        samePasswordCheck('password', value);
                        getPasswordValidationClass(value);
                    }}
                    placeholder="Enter your new password"
                    name="password"
                />
            </div>

            <div className="carouselSubTitle flex justify-start items-start mt-2 ml-1">
                Confirm your new password
            </div>

            <div>
                <PasswordInput
                    password={createPasswordState.confirmPassword}
                    setPassword={(value) => samePasswordCheck('confirmPassword', value)}
                    placeholder="Confirm your new password"
                    name="confirmPassword"
                />
                {samePassword && (
                    <div className="text-red-500 mt-1 text-center">
                        Password and confirm password do not match.
                    </div>
                )}
            </div>
            <div className="mt-6">
                <MessageComponent
                    type={type}
                    message="Your password must include upper and lowercase letters, at least 12 characters, one number, and one special character."
                />
            </div>
            <button
                className={`mt-6 h-10 w-full rounded-full text-lg font-semibold ${isButtonDisabled
                    ? 'bg-gray-400 '
                    : ' bg-loginBg text-white'
                    }`}
                onClick={handleCreatePassword}
                disabled={isButtonDisabled}
            >
                <div className="carouselSubTitle">Reset Password</div>
            </button>
            {renderMessage()}
        </div>
    );
};

export default CreatePasswordComponent;
