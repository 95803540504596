import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteFinancialAccountItem, fetchAccountDetail, fetchAccountSummaryByType, fetchAllTransactions, fetchAnalyzeData, fetchFinancialAccountItems, fetchLinkToken, fetchPublicAccessToken, fetchSalary, fetchSpendingCategoryTransaction, fetchUpcomingBills, postCategorySpendLimit, postSalary } from "../../services/TransactionService";


export const fetchLinkTokenAsync = createAsyncThunk(
  "user/fetchLinkToken",
  async (data:any, thunkAPI) => {
    try {
      const response = await fetchLinkToken(data);
      return response; // Ensure that `response.data` contains `link_token`.
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const fetchPublicAccessTokenAsync = createAsyncThunk(
  "user/fetchPublicAccessToken",
  async (data: any, thunkAPI) => {
    try {
      const response = await fetchPublicAccessToken(data);
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);


export const fetchAccountDetailAsync = createAsyncThunk(
  "user/fetchAccountDetail",
  async (_, thunkAPI) => {
    try {
      const response = await fetchAccountDetail();
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const fetchAllTransactionsAsync = createAsyncThunk(
  "user/fetchAllTransactions",
  async (_, thunkAPI) => {
    try {
      const response = await fetchAllTransactions();
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);


export const fetchSalaryAsync = createAsyncThunk(
  "user/fetchSalary",
  async (_, thunkAPI) => {
    try {
      const response = await fetchSalary();
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
export const postSalaryAsync = createAsyncThunk(
  "user/postSalary",
  async (data:any, thunkAPI) => {
    try {
      const response = await postSalary(data);
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
export const fetchAnalyzeDataAsync = createAsyncThunk(
  "user/fetchAnalyzeData",
  async (_, thunkAPI) => {
    try {
      const response = await fetchAnalyzeData();
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const fetchUpcomingBillsAsync = createAsyncThunk(
  "user/fecthUpcomingBills",
  async (_, thunkAPI) => {
    try {
      const response = await fetchUpcomingBills();
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);


export const fetchAccountSummaryByTypeAsync = createAsyncThunk(
  "user/fetchAccountSummaryByType",
  async (_, thunkAPI) => {

    try {
      const response = await fetchAccountSummaryByType();
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const fetchFinancialAccountItemsAsync = createAsyncThunk(
  "user/fetchFinancialAccountItem",
  async (_, thunkAPI) => {
    try {
      const response = await fetchFinancialAccountItems();
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const deleteFinancialAccountItemsAsync = createAsyncThunk(
  "user/deleteFinancialAccountItem",
  async (id:string, thunkAPI) => {
    try {
      const response = await deleteFinancialAccountItem(id);
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);


export const fetchSpendingCategoryTransactionAsync = createAsyncThunk(
  "user/fetchSpendingCategoryTransaction",
  async (finleyCategory:any, thunkAPI) => {
    try {
      const response = await fetchSpendingCategoryTransaction(finleyCategory);
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
)


export const postCategorySpendLimitAsync = createAsyncThunk(
  "user/postCategorySpendLimit",
  async (data:any, thunkAPI) => {
    try {
      const response = await postCategorySpendLimit(data);
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
