
import React, { useEffect, useRef, useState } from 'react';
import AppLogo from "../../../assets/AppLogo";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../store/store";
import { goToNextStep, validationStep } from "../../../store/slices/OnBoardingSlice";
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';


const OnBoarding: React.FC = () => {
    const dispatch = useDispatch();
    const currentStep = useSelector((state: RootState) => state.OnboardingStore.currentStep) || 1;
    const isValidStep = useSelector((state: RootState) => state.OnboardingStore.isValid) as validationStep;
    const mainContainerRef = useRef<HTMLDivElement>(null);
    const [isReadyForNext, setIsReadyForNext] = useState(false);
    const [showStep, setShowStep] = useState(false);
    const headerRef = useRef<HTMLDivElement>(null);
    const stepRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [dynamicPadding, setDynamicPadding] = useState(0);
    useEffect(() => {
        // Calculate dynamic padding based on container and header heights
        if (mainContainerRef.current && headerRef.current) {
            const containerHeight = mainContainerRef.current.clientHeight;
            const totalStepsHeight = Array.from(stepRefs.current).reduce((total, step) => {
                return total + (step?.offsetHeight || 0);
            }, 0);
            const headerHeight = headerRef.current.offsetHeight;

            // Calculate additional padding needed
            let requiredPadding = containerHeight - totalStepsHeight - headerHeight;
           
            setDynamicPadding(requiredPadding > 0 ? requiredPadding+100 : 0);
        }
    }, [currentStep, isValidStep]);
    useEffect(() => {
        if (currentStep < 4) {
            setIsReadyForNext(false);
            setShowStep(false); 
            const timeout = setTimeout(() => {
                setIsReadyForNext(true);
                setTimeout(() => setShowStep(true), 10);
            }, 10);

            return () => clearTimeout(timeout);
        }
    }, [currentStep]);

    useEffect(() => {
        const stepKey = `step${currentStep}` as keyof validationStep;
        const isValid = isValidStep[stepKey];
        setIsReadyForNext(!!isValid);
     }, [currentStep, isValidStep]);

 
    const handleNextStep = () => {
        dispatch(goToNextStep());
        setTimeout(() => {
            handleScrollToCurrentQuestion();
        }, 0);
    };
    const handleScrollToCurrentQuestion = () => {
        const headerHeight = headerRef.current?.offsetHeight || 0;
        const nextStepRef = stepRefs.current[currentStep + 1];

        if (nextStepRef && mainContainerRef.current) {
            const stepTop = nextStepRef.offsetTop;
            mainContainerRef.current.scrollTo({
                //top: currentStep!=2?stepTop - headerHeight + 118:stepTop - headerHeight +118,
                top: stepTop - headerHeight +118,
                behavior: "smooth",
            });
        }
    };

    const renderStep = () => {
        const stepsToRender = [];
        // Check for Step 1
        const step1Valid = isValidStep.step1 || currentStep===1; 
        if (step1Valid) {
            stepsToRender.push(
                <Step1 
                    key={1} 
                    ref={(el: HTMLDivElement | null) => stepRefs.current[1] = el} 
                    onNext={handleNextStep} 
                />
            );
        }
    
        // Check for Step 2
        const step2Valid = isValidStep.step2|| currentStep===2; 
        if (step2Valid) {
            stepsToRender.push(
                <Step2 
                    key={2} 
                    ref={(el: HTMLDivElement | null) => stepRefs.current[2] = el} 
                    onNext={handleNextStep} 
                />
            );
        }
    
        // Check for Step 3
        const step3Valid = isValidStep.step3 || currentStep===3; 
        if (step3Valid || currentStep === 3) { // Render Step 3 if valid or if it's the current step
            stepsToRender.push(
                <Step3 
                    key={3} 
                    ref={(el: HTMLDivElement | null) => stepRefs.current[3] = el} 
                    onNext={handleNextStep} 
                />
            );
        }
    
        // Check for Step 4
        if (currentStep === 4 || currentStep===4) { // Render Step 4 only if it's the current step
            stepsToRender.push(
                <Step4 
                    key={4} 
                    ref={(el: HTMLDivElement | null) => stepRefs.current[4] = el} 
                />
            );
        }
    
        return <>{stepsToRender}</>; // Return the array of rendered steps
    };
    
   
    return (
        <div className="relative h-screen flex justify-center container mx-auto  ">
            <div
                ref={headerRef}
                className="flex justify-between pt-16 items-center px-3 pb-3 backdrop-blur-sm absolute left-0 w-full z-10"
            >
                <div className="flex justify-center items-center mx-auto">
                    <AppLogo />
                    <div className="ml-2 carouselTitle">
                        <h4>Finley</h4>
                    </div>
                </div>
            </div>

            <div className={`h-full pt-32  p-3 overflow-y-auto scroll-smooth`} ref={mainContainerRef}   style={{ paddingBottom: currentStep > 1 ? dynamicPadding+'px' : '0px' }}  >
                <div className="relative w-full">
                    {/* Render the current step */}
                    <div
                        className={`w-full transition-all duration-500 ease-in-out mb-3 ${isReadyForNext ? 'fade-slide-in' : 'fade-slide-out'}`}
                        style={{
                            opacity: isReadyForNext ? 1 : 0,
                            pointerEvents: isReadyForNext ? 'auto' : 'none',
                        }}
                    >
                        {renderStep()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnBoarding;


