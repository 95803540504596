import { default as http } from "../rest/http";


export const fetchLinkToken = async (data: any) => {
  try {
    const response = await http.post("/transaction-service/plaid/link_token", data);
    return response;
  } catch (error) {
    console.error("Error fetching link token:", error);
    throw error;
  }
};

export const fetchPublicAccessToken = async (data: any) => {
  try {
    const response = await http.post("/transaction-service/plaid/access_token",data);
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

export const fetchAccountDetail = async () => {
  try {
    const response = await http.get("/transaction-service/accounts");
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

 const fetchTransaction = async (page:number) => {
  try {
    const response = await http.get("/transaction-service/plaid/transactions?per_page=100&page="+page);
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};


export const fetchSalary = async () => {
  try {
    const response = await http.get("/transaction-service/salary");
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

export const fetchUpcomingBills = async () => {
  try {
    const response = await http.get("/transaction-service/recurring/upcoming-bills");
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};


export const postSalary = async (data:any) => {
  try {
    const response = await http.post("/transaction-service/salary",data);
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

// Function to process API calls in batches of 10
export async function fetchAllTransactions() {
  let allTransactions = [];
  const firstPageData = await fetchTransaction(1);
 const totalPages = firstPageData.total_pages;
  allTransactions.push(...firstPageData.transactions);
 // If there is only one page, return early
 if (totalPages === 1) {
  return allTransactions;
}
  let pageNumbers = Array.from({ length: totalPages - 1 }, (_, i) => i + 2); // Pages 2 to totalPages

  // Process in batches of 10
  while (pageNumbers.length > 0) {
      const batch = pageNumbers.splice(0, 10); // Take the first 10 pages
      const batchPromises = batch.map(page => fetchTransaction(page));
      const batchResults = await Promise.all(batchPromises);

      // Extract transactions from each result
      batchResults.forEach(result => {
          allTransactions.push(...result.transactions);
      });
  }

  return allTransactions;
}
export const fetchAnalyzeData = async () => {
  try {
    const response = await http.get("/transaction-service/spending/analyze");
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

export const fetchFinancialAccountItems = async () => {
  try {
    const response = await http.get("/transaction-service/items");
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

export const deleteFinancialAccountItem= async (id:string) => {
  try {
    const response = await http.delete(`/transaction-service/items/${id}`);
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};
export const fetchAccountSummaryByType = async () => {

  try {
    const response = await http.get("/transaction-service/accounts/overview");
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

export const postCategorySpendLimit = async (data:any) => {
  try {
    const response = await http.post("/transaction-service/spending/limits",data);
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

export const fetchSpendingCategoryTransaction = async (finleyCategory:string) => {
  try {
    const response = await http.get(`/transaction-service/spending/category-overview?finleyCategory=${finleyCategory}`);
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};