import { Card, CardContent } from "../ui/Card";
import PlaidLinkComponent from "../plaid/PlaidLinkComponent";
import '../../App.css';
import Header from "../common/Header";
import FilteredAccounts from "./FilteredAccounts";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect } from "react";
import { fetchAccountDetailAsync } from "../../store/thunks/TransactionThunk";
import { getUserAccountDetailsAsync } from "../../store/thunks/MasterThunk";
import RightArrow from '../../assets/RightArrow.svg';
import useDeepLinking from "../../hooks/useDeepLinking";
import { OnboardingState } from "../../store/slices/MasterSlice";
import HeaderWithBackIcon from "../common/HeaderWithBackIcon";

export default function AccountDetails() {
  const dispatch = useDispatch<AppDispatch>();
  const { accounts } = useSelector((state: RootState) => state.TransactionStore);
  const { userAccount } = useSelector((state: RootState) => state.MasterStore);
  const navigate = useNavigate();
  const location = useLocation();

  useDeepLinking();
  useEffect(() => {

    if (location.pathname.includes('/callback')) {
      console.log('Callback route detected');
      dispatch(getUserAccountDetailsAsync());
      dispatch(fetchAccountDetailAsync());
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getUserAccountDetailsAsync());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchAccounts = async () => {
      await dispatch(fetchAccountDetailAsync());
    };

    fetchAccounts();
  }, [dispatch]);

  function handleClick(): void {
    if (userAccount.onboarding_state === OnboardingState.BANK_ACCOUNT_CONNECTED) {
      navigate('/survey')
    }
    else {
      navigate('/confirm-income')
    }
  }
  const handleBackClick = () => {
    navigate('/home')

  };
  return (
    <>
      <div className="lg:container lg:max-w-md h-screen flex flex-col bg-customBgColor">
        {userAccount.onboarding_state === OnboardingState.ONBOARDING_COMPLETE && <div className="pl-4"><HeaderWithBackIcon onBackClick={handleBackClick} /></div>}
        {userAccount.onboarding_state !== OnboardingState.ONBOARDING_COMPLETE && <div className="mt-5"> <Header /></div>}
        {/* Content area with flex-grow */}
        <div className="flex-grow overflow-y-auto p-3">
          <div className="flex mb-4 space-x-4">

            <div className="font-serif flex-col text-base font-normal leading-6 text-left">
              <div className="font-serif text-xl font-bold leading-7 tracking-tightCustom">Let's connect your account!</div>
              {userAccount.onboarding_state === OnboardingState.ONBOARDING_COMPLETE && <div className="text-darkBlue font-serif text-base font-normal leading-6 pt-3">Let’s start by confirming the accounts you want me to review. Feel free to add any other accounts you want me to take a look at.</div>}
              {userAccount.onboarding_state !== OnboardingState.ONBOARDING_COMPLETE && <div className="text-darkBlue font-serif text-base font-normal leading-6 pt-3">Please connect the account(s) that you use for your everyday purchases and bills so that I can help you manage your spending habits.</div>}
            </div>
          </div>

          {/* Cards with space between */}
          <div className="space-y-4">
            <Card
              className={`bg-white rounded-2xl w-full overflow-hidden transition-all duration-300 ${!accounts || (accounts && accounts.length === 0) ? 'h-[82px]' : 'min-h-[82px]'}`}
            >
              <CardContent className="pt-3">
                <div className="flex justify-between items-center">
                  <div className="text-center font-serif font-bold text-lg">Connect Accounts</div>
                  <PlaidLinkComponent />
                </div>
              </CardContent>
              {accounts && accounts.length > 0 && (
                <FilteredAccounts filterTypes={[]} />
              )}
            </Card>

            {/* Show this message if no accounts */}
            {accounts && accounts.length === 0 && (
              <div className="w-full text-darkBlue text-center font-inter text-[12px] font-medium leading-[20px]">
                At least one primary spending account is required.
              </div>
            )}
          </div>

        </div>

        {/* Sticky footer button */}
        <div className="sticky bottom-0 w-full bg-customBgColor py-3 px-3">
          {accounts && accounts?.length > 0 && (
            <button
              onClick={handleClick}
              className="flex justify-center items-center cursor-pointer w-full bg-loginBg text3 text-white px-8 py-2 rounded-full"

            >
              <img
                src={RightArrow}
                className="size-4 mr-2"
                alt="Arrow"
              />
              {`Continue with ${accounts?.length} ${accounts.length === 1 ? "account" : "accounts"}`}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
