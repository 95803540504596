
import { Field } from "@headlessui/react";
import { categoriesImage } from "../../common/categories";
import { roundUp } from "../../../utils/helper";
export interface CategoryComponentProps {
    categoryName: string,
    limitAmount: string,
    imageName: string,
    percentage: string,
    savedAmount: string,
    drasticallyAdjusted: boolean,
    monthlyAvgIncome: number|null
}

export function CategoryLimitComponent({ categoryName, limitAmount, imageName, percentage, savedAmount, drasticallyAdjusted, monthlyAvgIncome}: CategoryComponentProps) {
    const category = categoriesImage.find(
        (cat) => cat.categoryName.toLowerCase() === imageName.toLowerCase()
    );
        // Check if monthlyAvgIncome is available and not null
    let percentageCalculated: any;
 
    if (monthlyAvgIncome !== null && monthlyAvgIncome > 0) {
        const calculatedPercentage = (parseFloat(limitAmount) / monthlyAvgIncome) * 100;
        percentageCalculated = roundUp(calculatedPercentage,2); 
    }
  
    const icon = category?.icon || "";
    return (
        <>

            <div className="flex flex-col p-5 items-start gap-3 self-stretch rounded-2xl border border-neutral-200 bg-white">
                <div className="flex items-center gap-[var(--spacing-3,12px)] self-stretch">
                    {icon}
                    <div className="flex items-center gap-3 flex-1">
                        {categoryName}
                    </div>
                    <div className="flex flex-col gap-1.5 w-20">
                        <div className="flex items-center justify-center gap-2 px-3 py-2 self-stretch rounded-md border border-slate-200 bg-white text3" >
                            ${limitAmount}
                        </div>
                    </div>
                </div>
                <div className="text-start w-full">
                    <div className="intertext1 text-neutral-400" style={{ textAlign: 'left' }}>
                        You’ll save ${savedAmount}/mo by limiting your {categoryName} to {percentageCalculated}% of your monthly income.
                    </div>
                </div>
            </div>
        </>
    );
}
