import { useNavigate } from "react-router";
import Header from "../../common/Header";

const Survey = () => {
    const navigate = useNavigate();

    return (
        <div className="px-3">
            <Header />
            <div className="mt-[10px] carouselTitle  tracking-extra-tight">
                One More Step: Tell Me About Your Goals
            </div>
            <div className="text3 mt-3 text-darkBlue">
                Your accounts are connected! To make my recommendations even more helpful, I'd love to learn what matters most to you.

            </div>
            <div className="text3 text-darkBlue mt-4">
                Just a quick 60-second survey to get started! ⚡️
            </div>
            <div className="w-full mt-7 ">
                <button onClick={() => { navigate("/survey-que") }} className="bg-blackButton carouselSubTitle text-white w-full py-2 px-6 rounded-full mb-4">
                    Continue
                </button>
            </div>
        </div>
    )
}
export default Survey;
